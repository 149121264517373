import React from "react";
import { Article } from "components/lib";
import parse from "html-react-parser";

export function PracitceTerms(props) {
  return (
    <Article>
      {parse(`
The Katina Online Platform is not providing a health service, and we do not provide any healthcare or medical services. The Platform is designed by the purposes of matching patients with Health Practitioners based on the inputs of the patient and Health Practitioner. We accept no liability for the health service provided by a Health Practitioner to a patient, whether or not that patient was matched with the Health Practitioner through the Platform.

<br clear="ALL"/>
<strong>Practice Terms and Conditions</strong>
<p>
<strong>1</strong> <strong>Overview</strong>
</p>
<p>
1.1                Katina Online Pty  Ltd ACN 667 690 655 (<strong>we, us,
our</strong>) operates the platform called Katina  Online (<strong>Platform</strong>), 
a platform developed and owned exclusively by us. These  Terms and
Conditions (<strong>Terms and Conditions</strong>) record an agreement
between  you (<strong>you, your</strong>), as the user and us for the
purpose of your use of the Platform.
</p>
<p>
1.2                To the extent  that you are a health practice that is
structured as a company, trust or other  formal structure, you must procure
that your officers, associates, employees,  agents, and/or contractors that
may be using or accessing the Platform comply  with these Terms and
Conditions. We note that clause 13.1 makes you liable for any loss or
damage caused by an officer, associate, employee, agent or contractor using
the  Platform.
</p>
<p>
1.3                The Platform is  not providing a health service, and we
do not provide any healthcare or medical  services. The Platform is designed
for the purpose of matching patients with  appropriate qualified health
professionals at your practice (<strong>Health Practitioners</strong>) and
scheduling appointments between them based on the inputs  of the Health
Practitioners and patients. We are in no way liable for any  health services
provided by you or on your behalf to a patient, whether or not  that patient
was matched with a Health Practitioner through the Platform.
</p>
<p>
1.4                We and our third-party licensors reserve the right to
update,  modify, replace, limit, and expand the Platform and its
functionality.
</p>
<p>
1.5                Prior to using the Platform, you should read these Terms
and Conditions  carefully.
</p>
<p>
<strong>2</strong> <strong>Acceptance  of Terms and Conditions</strong>
</p>
<p>
2.1                By using the  Platform, you are accepting these Terms and
Conditions and agree to be bound by  them. <strong></strong>
</p>
<p>
2.2                We reserve the  right to make changes to these Terms and
Conditions from time to time without  notice. <strong></strong>
</p>
<p>
2.3                These Terms and Conditions apply continuously and  anew
each time you use the Platform. We reserve the right to make changes to
these Terms and Conditions from time to time without notice, and you should
check the Terms and Conditions upon each use of the Platform.
<strong>
</strong>
</p>
<p>
<strong>3</strong> <strong>Use</strong>
</p>
<p>
<a name="_Ref80283335">
    3.1                To use the  Platform, you may be required to supply
    the following information (<strong>User Data</strong>):
</a>
<strong></strong>
</p>
<p>
(a)                 Name  of your practice and ABN; <strong></strong>
</p>
<p>
(b)                 Your  registered address; <strong></strong>
</p>
<p>
(c)                 Your  email address; <strong></strong>
</p>
<p>
(d)                 Your  phone number; <strong></strong>
</p>
<p>
(e)                 Credit  card or bank account details for payment
purposes; <strong></strong>
</p>
<p>
(f)                  Any  other information reasonably required to use the
Platform. <strong></strong>
</p>
<p>
(g)                 The  following information relating to your Health
Practitioners, employees, agents  and contractors may also be required for
the purposes of using the Platform:
</p>
<p>
(i)                   full name; <strong></strong>
</p>
<p>
(ii)                 email  address; <strong></strong>
</p>
<p>
(iii)               where  applicable, the relevant years of practice
experience, areas of specialisation  and sufficient evidence of registration
and qualification details; <strong></strong>
</p>
<p>
(iv)                any  other information reasonably requested by us or
required for the operation of  the Platform. <strong></strong>
</p>
<p>
3.2                Failure to supply  correct User Data may result in you
being unable to use the Platform as  intended. <a
name="_Ref80344344"><strong></strong></a>
</p>
<p>
3.3                We may use the User  Data for the purpose of providing
the functionality of the Platform and any  related purpose. In order to do
so, we may share the data with other parties,  including stakeholders and
contractors. <strong></strong>
</p>
<p>
<a name="_Ref80353288">
    3.4                Our use of any  personal information provided within
    the User Data will be in accordance with  our Privacy Policy available
</a>
on our website at [insert link]. <strong></strong>
</p>
<p>
3.5                You must not use the Platform for any  unconscionable
behaviour or activity, including but not limited to:
</p>
<p>
(a)                 Engaging in abusive, derogatory, discriminatory, or
vexatious behaviour;
</p>
<p>
(b)                 Any behaviour that would or may constitute
non-compliance with Australian laws or regulations, or is not in keeping
with  the purpose of providing health services;
</p>
<p>
(c)                 Engaging in any activity that disrupts or corrupts  the
Platform or the networks that host the Platform; or
</p>
<p>
(d)                 Attempting to hack, disable, circumvent, or  otherwise
gain access to any security, database, or other secure aspect of the
Platform.
</p>
<p>
3.6                You may link to the Platform only: <strong></strong>
</p>
<p>
(a)                 With our prior express permission; <strong></strong>
</p>
<p>
(b)                 In a way that is fair, unbiased, legal and does not
damage or exploit our reputation; or <strong></strong>
</p>
<p>
(c)                 In a way that does not suggest either express or
implied any association or endorsement by us. <strong></strong>
</p>
<p>
3.7                You must remove or procure the removal of any link  to
the Platform immediately upon receiving notice from us to do so. We may give
such notice at our absolute discretion without justification.
<strong>
</strong>
</p>
<p>
3.8                You must not modify or adapt the Platform or modify
another website or software so as to falsely imply that it is associated
with  the Platform or us. You<strong></strong>must use the Platform for its
intended  purpose.  You may not use the Platform  for any illegal or
unauthorised purpose, including the transmission of  malware.  You must not
use the Platform to  violate any laws in any jurisdiction, including but not
limited to copyright  laws. For the avoidance of doubt, you must not
infringe upon the copyrights of  any other person in using the Platform.
<strong></strong>
</p>
<p>
<strong>4</strong> <strong>Advertisements</strong>
</p>
<p>
4.1                We may display advertisements by third party sponsors  on
the Platform (<strong>Ads</strong>). In doing so, we do not warrant the
functionality  of the business, product, or service being advertised in the
Ad. We do not hold  ourselves out as partners, affiliates, guarantors, or as
having any other type  of legal or professional relationship with the third
party sponsors. <strong></strong>
</p>
<p>
4.2                Where Ads link to third party websites, we do not
warrant the safety, functionality, or suitability of these websites. We are
in  no way liable for any loss, damage, risk or liability incurred from you
visiting any third party websites accessible through Ads. <strong></strong>
</p>
<p>
4.3                Advertised third party websites may be subject to their
own terms and conditions. We do not verify or warrant the legality or
suitability of those terms and conditions, and we encourage you to read them
before accessing or using the linked site. <strong></strong>
</p>
<p>
<a name="_Ref68778788"></a><a name="_Ref80284315"><strong>5</strong><strong>Fees 
and Payment</strong></a> <strong></strong>
</p>
<p>
5.1                By accepting these Terms and Conditions you authorise us
or any  third-party service provider to debit your account at the relevant
financial  institution connected with the payment details identified and
provided in your User  Data.  This may be for payment of your subscription
fee when you first subscribe to the Platform and any subsequent monthly
subscription fees (<strong>Fees</strong>). <strong></strong>
</p>
<p>
5.2                Fees and other  amounts payable to us for the Platform
are published on our website and are  charged in Australian Dollars. We
reserve the right to amend pricing rates from  time to time at our
discretion. <strong></strong>
</p>
<p>
5.3                Fees are payable monthly in advance and will  be direct
debited from your nominated bank account or credit card at the time  of
subscription, and then on each subsequent monthly anniversary of the
subscription date. <strong></strong>
</p>
<p>
5.4                If sufficient  funds are not available in your nominated
financial account at the time of  processing a payment: <strong></strong>
</p>
<p>
(a)                 We  will issue an email notification to you providing
notice of the payment  default. <strong></strong>
</p>
<p>
(b)                 A  second attempt to direct debit the fees from your
nominated bank account or  credit card will occur three days after the
failed attempt. <strong></strong>
</p>
<p>
(c)                 If  the payment is declined a second time, your
subscription will be temporarily  disabled until the payment is successfully
made. <strong></strong>
</p>
<p>
5.5                All charges are  inclusive of Goods and Service Tax (GST)
where applicable. <strong></strong>
</p>
<p>
<strong>6</strong> <strong>Third  party payment service</strong>
</p>
<p>
6.1                We use Stripe to process  payments on the Platform.
Payments submitted through the Stripe payment  processing service are
subject to Stripe’s terms and conditions which can be  viewed at
<a href="https://stripe.com/au">
    https://stripe.com/au
</a>
.
</p>
<p>
6.2                From time to time, we may use  a payment service provider
other than Stripe in which case payment will be  subject to the relevant
payment service provider’s terms and conditions. <strong></strong>
</p>
<p>
<strong>7</strong> <strong>Refunds</strong>
</p>
<p>
7.1                If you believe you are entitled to a refund you can
contact us through the contact details stated below. Any request for a
refund  must include, at a minimum:
</p>
<p>
(a)                 The reason why you believe you are entitled to a
refund;
</p>
<p>
(b)                 Evidence of the issue that warrants a refund.
</p>
<p>
7.2                We will investigate any request for a refund we  receive.
We may, at our absolute discretion, offer to refund the service, offer  you
a credit for the value of the service during the subscription period, or
offer a full or partial refund for the service.
</p>
<p>
7.3                Subject to any rights you may have under Australian
Consumer Law, all requests for refunds will be dealt with at our absolute
discretion.
</p>
<p>
7.4                Unless we determine otherwise at our absolute
discretion, requests for refunds due to a change of mind will not be
considered.
</p>
<p>
7.5                Notwithstanding any other provision of these Terms  and
Conditions, we are in no way required to refund any amount.
</p>
<p>
<strong>8</strong> <strong>Intellectual Property</strong>
</p>
<p>
8.1                Your use of the Platform is pursuant to a non-exclusive
license between you and us which is granted subject to these Terms and
Conditions. Nothing in these Terms and Conditions or otherwise creates an
assignment of any rights in the Platform beyond the ability to use it for
its  intended use. <strong></strong>
</p>
<p>
8.2                Nothing in these Terms and Conditions or otherwise
creates the right for you to sublicense the Platform. <strong></strong>
</p>
<p>
8.3                You undertake not to reverse engineer, replicate, or
otherwise copy the Platform. We reserve all our rights to seek compensation,
damages, injunctions, or any other remedy available to us at law if any
attempt  to do so, whether successful or unsuccessful, is made by you or any
of your  affiliates. <strong></strong>
</p>
<p>
8.4                You must not, and ensure your Health Practitioners,
employees, agents and contractors must not, reproduce, duplicate, copy,
sell,  resell or exploit any portion of the Platform through usage of the
Platform, or  access to the Platform without our prior express written
permission. We claim  copyright and all other intellectual property rights
over the Platform, its  other services and reserve all of our rights.
<strong></strong>
</p>
<p>
8.5                All intellectual property and ownership rights
subsisting in the Platform (including the user interface and the software
comprising the Platform), the data derived from use of the Platform, and any
other documentation, information or materials that are supplied by us to
you,  remain our or our third party licensors’ exclusive property.  For the
avoidance of doubt, nothing in this  agreement assigns any ownership rights
to you.
</p>
<p>
<strong>9</strong> <strong>Operation of the Platform</strong>
</p>
<p>
9.1                You warrant that you will comply fully with all Platform
operating instructions. Any failure to comply fully with operating
instructions  may result in the sub-par or faulty performance of the
Platform, including  incorrect matching and assignment of patients.
<strong>
</strong>
</p>
<p>
9.2                We accept no liability, and you release us from any  such
liability, associated with faults or errors caused by you, or your agents,
employees or contractors, non-compliance with all operating instructions.
<strong></strong>
</p>
<p>
9.3                We may suspend the Platform for any one or more periods
of time if we are unable to provide the Platform due to a fault or
dysfunction  with our servers. <strong></strong>
</p>
<p>
9.4                You warrant that you will procure the compliance of  your
Health Practitioners, and all employees, agents and contractors engaged,
with  all Platform operating instructions.
</p>
<p>
<strong>10</strong> <strong>Modifications to the Platform</strong>
</p>
<p>
We reserve the right to modify or  discontinue, temporarily or permanently
the Platform and its other services  with or without notice.
<strong>
</strong>
</p>
<p>
<strong>11</strong> <strong>Warranties</strong>
</p>
<p>
11.1            You represent and warrant that we will not be liable  for
any direct or indirect expense, damage or injury (including indirect loss
of revenue, profits, goodwill or business opportunity) howsoever caused,
including arising directly or indirectly from or in connection with your use
of  the Platform. <strong></strong>
</p>
<p>
11.2            Subject to any of the rights you may have under Australian
Consumer Protection Law, we exclude to the fullest extent possible all
implied  terms and warranties, whether statutory or otherwise, relating to
the Platform or  any other matter under these Terms and Conditions.
<strong>
</strong>
</p>
<p>
11.3            The Platform is made available to you on an “as is”  basis.
We make no warranties, representations or conditions of any kind,  express,
statutory or implied as to: <strong></strong>
</p>
<p>
(a)                 The operation and functionality of the Platform;
<strong></strong>
</p>
<p>
(b)                 the accuracy, integrity, completeness, quality,
legality, usefulness, safety and intellectual property rights of any of the
software content or its client matching abilities; and/or <strong></strong>
</p>
<p>
(c)                 the services associated with the Platform or its
content. <strong></strong>
</p>
<p>
11.4            The Platform schedules appointments for patients with
Health Practitioners based on the inputs of the patient and the Health
Practitioner. We make no warranty or guarantee that the resulting match
between  patient and Health Practitioner will be suitable, including but not
limited to  in circumstances where the patient or the Health Practitioner
has provided  inaccurate or incomplete information. <strong></strong>
</p>
<p>
11.5            You warrant that you possess all the necessary  insurances
and licenses to operate your practice in compliance with applicable  laws
and regulations. You further warrant that all your agents, employees and
contractors are qualified to the extent required to provide psychology
services  pursuant to the licensing and registration requirements of the
governing body, including  requirements of the Australian Health
Practitioner Regulation Agency. <strong></strong>
</p>
<p>
11.6            We further disclaim all warranties, express, statutory  or
implied, including, but not limited to, implied warranties of
merchantability, merchantable quality, durability, fitness for a particular
purpose and/or non-infringement. No advice or information, whether oral or
written, that you obtain from us shall create any warranty, representation
or  condition not expressly stated herein. <strong></strong>
</p>
<p>
<strong>12</strong> <strong>Limitation of liability</strong>
</p>
<p>
12.1            We disclaim all liability for any: <strong></strong>
</p>
<p>
(a)                 indirect, special, incidental, punitive, exemplary,
reliance or consequential damages; <strong></strong>
</p>
<p>
(b)                 loss of profits; <strong></strong>
</p>
<p>
(c)                 business interruption; <strong></strong>
</p>
<p>
(d)                 network interruptions; <strong></strong>
</p>
<p>
(e)                 loss or damage to reputation to you or any third party;
<strong></strong>
</p>
<p>
(f)                  loss of information or data; <strong></strong>
</p>
<p>
(g)                 all liability for any loss or damage, <strong></strong>
</p>
<p>
relating to or arising out of your  use of the Platform. <strong></strong>
</p>
<p>
12.2            Your use of the Platform is at your own discretion and
risk, and you will be solely responsible for any resulting loss or damage,
including but not limited to, any loss of data or damage to your computer(s)
or  networks from viruses that may be downloaded to your computer in the
course of  using the Platform and its software. Some jurisdictions do not
allow the  exclusion of certain warranties, the limitation or exclusion of
implied  warranties, or limitations on how long an implied warranty may
last, so the  above limitations may not apply to you. If you reside in such
a jurisdiction,  the above limitations shall apply to you to the fullest
extent permitted under  applicable law. <strong></strong>
</p>
<p>
12.3            Our maximum aggregate liability to you for any claims,
damages, injuries or causes whatsoever, and regardless of the form of action
(whether such liability arises due to negligence, breach of contract,
misrepresentation or for any other reason), will at all times be limited to
the lesser of (i) the  amount paid, if any, by you to us in connection with
the Platform in the 6  months prior to the action giving rise to liability
or (ii) the actual damages  incurred. <strong></strong>
</p>
<p>
<strong>13</strong> <strong>Indemnity</strong>
</p>
<p>
<a name="_Ref135658305">
    13.1            You defend, indemnify and hold harmless us, our
    members, managers, subsidiaries, affiliates, any related companies,
    contractors,  licensors and partners, and the officers, directors,
    employees, agents and  representatives of each of them, including costs,
    liabilities and legal fees,  from any claim or demand made by any third
    party due to or arising out of:
</a>
<strong></strong>
</p>
<p>
(a)                 Your access to or use of the Platform; <strong></strong>
</p>
<p>
(b)                 Any action or inaction of your Health Practitioners,
officers, associates, employees,  agents, and/or contractors through your or
their access or usage of the  Platform;
</p>
<p>
(c)                 Your violation of this agreement; <strong></strong>
</p>
<p>
(d)                 Any infringement by you of any intellectual  property or
other right of any person or entity. <strong></strong>
</p>
<p>
13.2            We reserve the right, at your expense, to assume the
exclusive defence and control of any matter for which you are required to
indemnify us and you will cooperate with our defence of these claims. You
will  not settle any matter without our prior written consent. We will use
reasonable  efforts to notify you of any such  claim, action or proceeding
upon becoming aware of it. <strong></strong>
</p>
<p>
<strong>14</strong> <strong>Transfer of rights and obligations</strong>
</p>
<p>
14.1            These Terms and Conditions are binding on you and  us and on
our respective successors and assignees.
</p>
<p>
14.2            You may not transfer, assign, charge or otherwise  dispose
of your rights or liabilities under these Terms and Conditions, or any  of
your rights or obligations arising under it, without our prior written
consent.
</p>
<p>
14.3            We may transfer, assign, charge, sub-contract or  otherwise
dispose of any of our rights or obligations arising under these Terms  and
Conditions, at any time without your prior consent.
</p>
<p>
<strong>15</strong> <strong>Force Majeure Events</strong>
</p>
<p>
15.1            We will not be liable or responsible for any  failure to
perform or delay in performance of any of our obligations under  these Terms
and Conditions that is caused by events outside our control (<strong>Force
Majeure Event</strong>).
</p>
<p>
15.2            A Force Majeure Event includes any act, event, non-
happening, omission or accident beyond our reasonable control and includes
in  particular (without limitation) the following:
</p>
<p>
(a)                 Strikes, lock-outs or other industrial action;
</p>
<p>
(b)                 Civil commotion, riot, invasion, terrorist attack  or
threat of terrorist attack, war (whether declared or not) or threat or
preparation for war;
</p>
<p>
(c)                 Fire, explosion, storm, flood, earthquake,  subsidence,
epidemic or other natural disaster;
</p>
<p>
(d)                 Impossibility of the use of railways, shipping,
aircraft, motor transport or other means of public or private transport;
</p>
<p>
(e)                 Impossibility of the use of public or private
telecommunications  networks;
</p>
<p>
(f)                  Epidemic, pandemic or other health emergency  (whether
declared or not), including without limitation lockdowns or any other
restrictions imposed as a result of the COVID-19 pandemic or any other
pandemic  or epidemic at any time; and
</p>
<p>
(g)                 The acts, decrees, legislation, regulations or
restrictions of any government.
</p>
<p>
15.3            Our performance under these Terms and Conditions is  deemed
to be suspended for the period that the Force Majeure Event continues,  and
we will have an extension of time for performance for the duration of that
period. We will use our reasonable endeavours to bring the Force Majeure
Event  to a close or to find a solution by which our obligations under these
Terms and  Conditions may be performed despite the Force Majeure Event.
</p>
<p>
<strong>16</strong> <strong>General</strong>
</p>
<p>
16.1            To the extent permitted by law, we reserve the right to
create, collect and / or store any metadata resulting from your use of the
Platform. To the extent permitted by law, we may use metadata for a variety
of  functions including (but not limited to) conducting investigations,
helping us  manage and administer the Platform, for purposes of search
engine optimisation  and to drive traffic to the Platform.  To  the extent
permitted by law, we reserve the right to share your information  (including
metadata) with government agencies and authorities to the extent  this is
required or requested of us. <strong></strong>
</p>
<p>
16.2            We may use third party vendors and hosting partners to
provide the necessary hardware, software, networking, storage, payment
gateway  and related technology required to run the Platform.
<strong>
</strong>
</p>
<p>
16.3            Verbal, physical, written or other abuse (including  threats
of abuse or retribution) of any of our employees, members,  representatives,
or officers will result in immediate termination of your  access to the
Platform. <strong></strong>
</p>
<p>
16.4            We do not warrant that: <strong></strong>
</p>
<p>
(a)                 The Platform will meet your specific requirements;
<strong></strong>
</p>
<p>
(b)                 The Platform will be uninterrupted, timely, secure, or
error-free; <strong></strong>
</p>
<p>
(c)                 Any errors in the Platform will be corrected.
<strong>
</strong>
</p>
<p>
16.5            Our failure to exercise or enforce any right or  provision
of these Terms and Conditions shall not constitute a waiver of such  right
or provision. <strong></strong>
</p>
<p>
16.6            The Terms and Conditions constitutes the entire  agreement
between you and us and govern your use of the Platform, superseding  any
prior agreements between you and us. <strong></strong>
</p>
<p>
16.7            Any part of these Terms and Conditions that is invalid,
prohibited or not enforceable will not void the balance of the Terms and
Conditions.  All other parts will be  enforced without regard to the invalid
provision and continue to be valid in  accordance with their terms.
<strong>
</strong>
</p>
<p>
16.8            We make no representations or warranties that the  content
of the Platform complies with the laws (including intellectual property
laws) of any country outside of Australia. <strong></strong>
</p>
<p>
16.9            Any queries about the Terms and Conditions should be  sent
to us by email at [insert  email]. <strong></strong>
</p>
<p>
16.10        These Terms and Conditions are governed by the laws of the
Victoria in Australia. Disputes arising from your use of the Platform are
exclusively subject to the jurisdiction of the courts of Victoria.
<strong>
</strong>
</p>
`)}
    </Article>
  );
}
