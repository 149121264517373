import React, { Fragment, useEffect, useState } from "react";
import { Form, useAPI, Card, Table, AccountNav } from "components/lib";
import Axios from "axios";

export function ManagePractice(props) {
  const practices = useAPI("/api/practice");
  const [practiceList, setPracticeList] = useState([]);

  useEffect(() => {
    if (practices?.data?.length > 0) {
      setPracticeList(practices.data);
    }
  }, [practices.data]);

  const handleDelete = (row) => {
    const newList = practiceList.filter((item) => item.id !== row.id);
    Axios.delete(`/api/practice/${row.id}`);
    setPracticeList(newList);
  };

  return (
    <Fragment>
      <AccountNav />
      <Card restrictWidth title="Add Location">
        <Form
          inputs={{
            name: {
              label: "Location Name",
              type: "text",
              required: true,
            },
            active: {
              type: "hidden",
              value: true,
            },
            nickname: {
              label: "Nickname",
              type: "text",
              required: false,
            },
          }}
          buttonText="Add"
          url="/api/practice"
          method="POST"
          callback={(data) => {
            setPracticeList([...practiceList, data]);
          }}
        />
      </Card>
      <Card title="Existing Locations">
        <Table
          data={practiceList}
          show={["name", "nickname"]}
          // actions={{ delete: handleDelete }}
        />
      </Card>
    </Fragment>
  );
}
