import { Fragment, useState, useEffect, useMemo } from "react";
import { AccountNav, useAPI } from "components/lib";
import MaterialReactTable from "material-react-table";

export function PractitionerSearch(props) {
  const usersAPI = useAPI("/api/user/psych_profiles");
  const [users, setUsers] = useState([]);

  const getPresentingIssues = (psych_data) => {
    const {
      mentalHealthSubCategories,
      neurodivergenceSubCategories,
      genderSubCategories,
      generalHealthSubCategories,
      traumaSubCategories,
      personalSubCategories,
      relationshipSubCategories,
      educationalSubCategories,
      addictionSubCategories,
      workSubCategories,
      legalSubCategories,
      subPersonalityDisorders,
      eatingSubCategories,
      anxietySubCategories,
      therapySubCategories,
    } = psych_data;

    const psychPresentingIssues = [].concat(
      mentalHealthSubCategories,
      generalHealthSubCategories,
      traumaSubCategories,
      personalSubCategories,
      relationshipSubCategories,
      educationalSubCategories,
      addictionSubCategories,
      workSubCategories,
      legalSubCategories,
      subPersonalityDisorders,
      eatingSubCategories,
      anxietySubCategories,
      therapySubCategories,
      neurodivergenceSubCategories,
      genderSubCategories
    );

    const filteredPsychPresentingIssues = psychPresentingIssues.filter(
      (p) => p !== undefined
    );

    return filteredPsychPresentingIssues.join(", ");
  };

  useEffect(() => {
    console.log(usersAPI);
    if (usersAPI.loading === false && usersAPI.data) {
      const updatedUsers = usersAPI.data.map((user) => {
        return {
          name: `${user.name}`,
          id: user.id,
          email: user.email,
          age: user?.psych_data?.age?.join(", ") || "NA",
          professionalDiscipline:
            user?.psych_data?.professionalDiscipline || "NA",
          psycologyType: user?.psych_data?.psycologyType?.join(", ") || "NA",
          assessments: user?.psych_data?.assessments?.join(", ") || "NA",
          mainReason: user?.psych_data?.mainReason?.join(", ") || "NA",
          presenting_issues: getPresentingIssues(user?.psych_data) || "NA",
        };
      });
      console.log(updatedUsers);
      setUsers(updatedUsers);
    }
  }, [usersAPI]);

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorFn: (row) => <a href={`/account/user/${row.id}`}>{row.name}</a>,
      },
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Ages Seen",
        accessorKey: "age",
      },
      {
        header: "Professional Discipline",
        accessorKey: "professionalDiscipline",
      },
      {
        header: "Psychology Type",
        accessorKey: "mainReason",
      },
      {
        header: "Presenting Issues",
        accessorKey: "presenting_issues",
      },
      {
        header: "Assessments",
        accessorKey: "assessments",
      },
    ],
    []
  );

  return (
    <>
      <AccountNav />
      <MaterialReactTable
        title="Practitioner Search"
        data={users}
        columns={columns}
        initialState={{
          columnVisibility: {
            id: false,
          },
        }}
      />
    </>
  );
}
