import { Home } from "views/website/home";
import { Contact } from "views/website/contact";
import { Pricing } from "views/website/pricing";
import { Terms } from "views/website/terms";
import { Privacy } from "views/website/privacy";
import { PublicClientView } from "views/website/client";
import { Signin } from "views/auth/signin";
import { PracitceTerms } from "views/website/practice-terms";
import { PractitionerTerms } from "views/website/practitioner-terms";
import { Thanks } from "views/website/thanks";

const Routes = [
  {
    path: "/",
    // view: Home,
    view: Signin,
    layout: "auth",
    title:
      "Katina: All Your Client Referrals In One Place: Centralised, Organised,Prioritised",
  },
  {
    path: "/contact",
    view: Contact,
    layout: "home",
    title: "Contact",
  },
  {
    path: "/pricing",
    view: Pricing,
    layout: "home",
    title: "Pricing",
  },
  {
    path: "/privacy",
    view: Privacy,
    layout: "home",
    title: "Privacy Policy",
  },
  {
    path: "/thanks",
    view: Thanks,
    layout: "onboarding",
    title: "Thanks",
  },
  {
    path: "/public/waitlist/:id",
    view: PublicClientView,
    layout: "onboarding",
    title: "Client View",
  },
  {
    path: "/practice-terms",
    view: PracitceTerms,
    layout: "home",
    title: "Practice Terms & Conditions",
  },
  {
    path: "/practitioner-terms",
    view: PractitionerTerms,
    layout: "home",
    title: "Practitioner Terms & Conditions",
  },
  {
    path: "/terms",
    view: Terms,
    layout: "home",
    title: "Terms & Conditions",
  },
];

export default Routes;
