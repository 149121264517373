import React, { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useAPI, Button, Link } from "components/lib";
import MaterialReactTable from "material-react-table";
import { Box, Typography } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export function PsychLeaderboard(props) {
  const { id } = useParams();
  const psychMatchesAPI = useAPI(`/api/match/psych/${id}`);
  const [psychMatches, setPsychMatches] = React.useState([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (psychMatchesAPI?.data && !psychMatchesAPI?.loading) {
      const unmatchedClients = psychMatchesAPI?.data.filter((m) => !m.user_id);
      const enrichedClients = unmatchedClients.map((c) => {
        return {
          ...c,
          urgent: c.urgent ? "Yes" : "No",
          review: c.review ? "Yes" : "No",
          admin_review: c.admin_review ? "Yes" : "No",
          priority: c.priority ? "Yes" : "No",
        };
      });
      setPsychMatches(enrichedClients);
    }
  }, [psychMatchesAPI]);

  const handleEmail = async () => {
    const ids = Object.keys(rowSelection);
    console.log("ids", ids);
    const clients = psychMatchesAPI.data.filter((c) => ids.includes(c.id));
    console.log("clients", clients);
    // redirect to bulk page with emails as query params
    window.location = `/bulk?ids=${ids.join(",")}`;
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 30,
      },
      {
        id: "presenting_issues",
        size: 25,
        accessorFn: (row) => {
          return row.presenting_issues ? (
            <CheckCircle />
          ) : (
            <Cancel color="error" />
          );
        },
        header: "Presenting Issues",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "assessment",
        size: 25,
        accessorFn: (row) => {
          return row.assessment ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Assessment",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "age",
        size: 25,
        accessorFn: (row) => {
          return row.age ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Age",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "payment_type",
        size: 25,
        accessorFn: (row) => {
          return row.payment_type ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Referral Type",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "location",
        size: 25,
        accessorFn: (row) => {
          return row.location ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Location",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "availability",
        size: 25,
        accessorFn: (row) => {
          return row.availability ? <CheckCircle /> : <Cancel color="error" />;
        },
        header: "Availability",
        muiTableBodyCellProps: {
          align: "center",
        },
        muiTableHeadCellProps: {
          align: "center",
        },
      },
      {
        id: "best_match",
        size: 25,
        accessorFn: (row) => {
          return (
            row.presenting_issues +
            row.assessment +
            row.age +
            row.payment_type +
            row.location +
            row.availability
          );
        },
        header: "Best Match",
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        data={psychMatches}
        columns={columns}
        loading={psychMatchesAPI?.loading}
        initialState={{
          columnVisibility: {
            best_match: false,
          },
          sorting: [{ id: "best_match", desc: true }],
        }}
        enableRowActions
        enableRowSelection
        enableSelectAll
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
        getRowId={(originalRow) => originalRow.id}
        renderTopToolbarCustomActions={() => (
          <>
            <div className="ml-auto pt-2">
              <Button
                action={handleEmail}
                icon="mail"
                color="black"
                textOnly
                outline
                alignLeft
                text="Email"
              />
            </div>
          </>
        )}
        positionActionsColumn="first"
        renderRowActions={(row, index) => [
          <>
            <Button
              action={(row) => {
                console.info("View", row);
                navigate("/client/" + row.row.original.id);
              }}
              params={row}
              small
              text="View"
            />
          </>,
        ]}
        renderDetailPanel={({ row }) => {
          const client = psychMatchesAPI?.data?.find(
            (c) => c.id === row.original.id
          );

          const {
            therapySubCategories,
            mentalHealthSubCategories,
            generalHealthSubCategories,
            traumaSubCategories,
            personalSubCategories,
            genderSubCategories,
            relationshipSubCategories,
            educationalSubCategories,
            addictionSubCategories,
            workSubCategories,
            legalSubCategories,
            subPersonalityDisorders,
            eatingSubCategories,
            anxietySubCategories,
          } = client.data;

          const otherIssues = []
            .concat(
              therapySubCategories,
              mentalHealthSubCategories,
              generalHealthSubCategories,
              traumaSubCategories,
              personalSubCategories,
              genderSubCategories,
              relationshipSubCategories,
              educationalSubCategories,
              addictionSubCategories,
              workSubCategories,
              legalSubCategories,
              subPersonalityDisorders,
              eatingSubCategories,
              anxietySubCategories
            )
            .filter((c) => c !== undefined);

          return (
            <Box
              sx={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr",
                width: "100%",
              }}
            >
              <Typography>
                <strong>Email:</strong>{" "}
                <a href={`mailto:${client.data.email}`}>{client.data.email}</a>
              </Typography>
              <Typography>
                <strong>Phone:</strong> {client.data.phone}
              </Typography>
              <Typography>
                <strong>Age:</strong> {client.data.Age?.toFixed(1)}
              </Typography>
              <Typography>
                <strong>Locations:</strong>{" "}
                {client.data.availPanel.map((l) => l.practiceName).join(", ") ||
                  "None"}
              </Typography>
              <Typography>
                <strong>Sub category:</strong> {otherIssues.join(", ") || "-"}
              </Typography>
              <Typography>
                <strong>Additional Info:</strong> {client.data.additionalInfo}
              </Typography>
              {client.data.assessments && (
                <Typography>
                  <strong>Assessments:</strong> {client.data.assessments}
                </Typography>
              )}
              <Typography>
                <strong>Comments:</strong>
                <span
                  dangerouslySetInnerHTML={{ __html: client.comment }}
                ></span>{" "}
                (<Link url={`/match/${client.id}`} text="Click to edit"></Link>)
              </Typography>
            </Box>
          );
        }}
        muiTableBodyRowProps={(row) => {
          if (row.row.original.urgent === "Yes") {
            return {
              style: {
                backgroundColor: "#d95565",
              },
            };
          }

          if (row.row.original.review === "Yes") {
            return {
              style: {
                backgroundColor: "#D3D3D3",
              },
            };
          }

          if (row.row.original.admin_review === "Yes") {
            return {
              style: {
                backgroundColor: "#90EE90",
              },
            };
          }

          if (row.row.original.priority === "Yes") {
            return {
              style: {
                backgroundColor: "#FFF700",
              },
            };
          }
        }}
      />
    </>
  );
}
