import React, { useMemo } from "react";
import { ElementFactory, Question, Serializer } from "survey-core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
    SurveyQuestionElementBase,
    ReactQuestionFactory
} from "survey-react-ui";

const CUSTOM_TYPE = "quill";

// Create a question model
export class QuestionQuillModel extends Question {
    getType() {
        return CUSTOM_TYPE;
    }
    get height() {
        return this.getPropertyValue("height");
    }
    set height(val) {
        this.setPropertyValue("height", val);
    }
}

// Register the model in `ElementFactory`
ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
    return new QuestionQuillModel(name);
});

// Add question type metadata for further serialization into JSON
Serializer.addClass(
    CUSTOM_TYPE,
    [
        { name: "height", default: "100%", category: "layout" },
    ],
    function () {
        return new QuestionQuillModel("");
    },
    "question"
);

// Create a class that renders Quill
export class SurveyQuestionQuill extends SurveyQuestionElementBase {
    constructor(props) {
        super(props);
    }
    get question() {
        return this.questionBase;
    }
    get value() {
        return this.question.value;
    }
    handleValueChange = (val) => {
        this.question.value = val;
    };
    // Support the read-only and design modes
    get style() {
        return { height: this.question.height };
    }

    renderQuill() {
        const isReadOnly = this.question.isReadOnly || this.question.isDesignMode;
        return (
            <ReactQuill
                readOnly={isReadOnly}
                value={this.value}
                onChange={this.handleValueChange}
                modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],        // toggled buttons
                      ['link', 'image'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom 
                      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                      [{ 'font': [] }],
                    ]
                  }}
            />
        );
    }

    renderElement() {
        return <div style={this.style}>{this.renderQuill()}</div>;
    }
}

// Register `SurveyQuestionQuill` as a class that renders `quill` questions
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
    return React.createElement(SurveyQuestionQuill, props);
});