import React, { useContext } from "react";
import { AuthContext, Link } from "components/lib";

export function InstructionsView(props) {
  const context = useContext(AuthContext);

  return (
    <ul>
      <li>1. Log in to your website portal</li>
      <li>2. Create a Button called "Add a Referral"</li>
      <li>
        3. Link the button to this{" "}
        <Link
          url={`/public/waitlist/${context?.user?.account_id}`}
          target="_blank"
          text="URL"
        />{" "}
      </li>
      <li>
        4. The Client/Referrer will be redirected to the Client Form to complete
        so they can be added to your waitlist.
      </li>
    </ul>
  );
}
