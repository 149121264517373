import React, { Fragment, useState, useCallback } from "react";
import { Form, useAPI, Card, AccountNav } from "components/lib";
import { useNavigate } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import "survey-core/defaultV2.css";
import Axios from "axios";
import { useEffect } from "react";
StylesManager.applyTheme("defaultV2");

export function CustomizeAccount(props) {
  const accountInfo = useAPI("/api/account");
  const [choices, setChoices] = useState([]);

  // get templates
  useEffect(() => {
    async function getTemplates() {
      const response = await Axios.get("/api/template");
      const templates = response.data.data.map((t) => {
        return { value: t.id, text: t.name };
      });
      console.log("templates", templates);
      setChoices(templates);
      // json.pages[0].elements[3].choices = templates;
    }
    getTemplates();
  }, [accountInfo]);

  const uploadFiles = useCallback(async (sender, options) => {
    // console.log("sender", sender);
    // console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("id", accountInfo.data.id);
    });

    const uploadResponse = await Axios.post("/api/utility/upload-logo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("uploadResponse", uploadResponse);

    options.callback(
      "success",
      options.files.map(function (file) {
        console.log("file", file);
        return {
          file: file,
          content: uploadResponse?.data?.data?.files?.find(
            (f) => f.filename === file.name
          )?.url,
        };
      })
    );

  });

  // form json
  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    pages: [
      {
        name: "info",
        elements: [
          {
            type: "text",
            isRequired: true,
            name: "color",
            title: "Brand Colour (add your hex colour code)",
            validators: [
              {
                text: "Please enter a valid hex color",
                type: "regex",
                regex: "^#(?:[0-9a-fA-F]{3}){1,2}$",
              },
            ],
          },
          {
            type: "quill",
            name: "introText",
            title: "Intro Text",
            description: "This text will be displayed at the top of your client form.",
          },
          {
            type: "file",
            inputType: "url",
            name: "logoUrl",
            title: "Logo URL",
            description:
              "You can use the default Katina image by leaving this field blank or upload your own logo.",
              allowMultiple: false,
              allowImagesPreview: true,
              acceptedTypes: ".jpg,.png",
              storeDataAsText: false,
              waitForUpload: true,
              maxSize: 2000000,
          },
          // {
          //   type: "text",
          //   inputType: "email",
          //   name: "contactEmail",
          //   title: "Support Email",
          //   // isRequired: true,
          // },
          {
            type: "dropdown",
            name: "clientEmailTemplate",
            title: "Automated Response to Clients Who Are Added to your Waitlist",
            description: "In the template section of your account, please create a template to be sent to clients after they complete a client form, then select it here. If you leave this section blank, the default text created by Katina will be used.",
            choices: choices,
            maxSelectedChoices: 1,
          },
        ],
      },
    ],
    showQuestionNumbers: "off",
  };

  function setExistingSettings() {
    if (!accountInfo?.data?.settings) {
      return;
    }

    model.setValue("color", accountInfo.data.settings.color);
    model.setValue("logoUrl", accountInfo.data.settings.logoUrl);
    model.setValue("contactEmail", accountInfo.data.settings.contactEmail);
    model.setValue("clientEmailTemplate", accountInfo.data.settings.clientEmailTemplate);
    model.setValue("introText", accountInfo.data.settings.introText);
  }

  const createOrUpdateAccountSettings = useCallback((sender) => {
    saveSurveyResults("/api/account/settings", sender.data);
  });

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.patch(url, {
      settings: json,
    });
  }

  // create model
  const model = new Model(json);
  model.onComplete.add(createOrUpdateAccountSettings);
  model.onUploadFiles.add(uploadFiles);

  if (choices.length === 0) {
    return <div>Please add a template before continuing.</div>;
  }

  setExistingSettings();
  return (
    <Fragment>
      <AccountNav />
      <Card
        title="Customise"
        loading={accountInfo?.loading && accountInfo?.data}
      >
        {<Survey model={model} />}
      </Card>
    </Fragment>
  );
}
