/***
 *
 *   PRIVACY POLICY
 *   Boilerplate privacy statement – replace the values
 *   inside [] with your own text
 *
 **********/

import React from "react";
import { Article } from "components/lib";
import parse from "html-react-parser";

export function Privacy(props) {
  return (
    <Article>
      {parse(`   <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:center;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">Privacy Policy</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:center;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">This Privacy Policy of</span>
        <span style="font-size:15px;color:black;background:white;">&nbsp;</span>
        <span style="font-size:15px;">
          Katina Online Pty Ltd ACN 667 690 655
          <span style="color:black;background:white;">
            &nbsp;(<strong>Katina</strong>)
          </span>
          <span style="color:black;">&nbsp;</span>
        </span>
        <span style="font-size:15px;color:black;">
          (<strong>us, we, our)</strong> sets out how we treat the personal and
          sensitive information that we collect, use and disclose and our
          procedures regarding the handling of personal and sensitive
          information, including the collection, use, disclosure and storage of
          information, as well as the right of individuals to access and correct
          that information. This Privacy Policy applies to the platform operated
          by Katina and ancillary platforms which facilitate the operation of
          the psychologist matching platform which, as at the date of this
          Privacy Policy is called &ldquo;Katina Online&rdquo; (
          <strong>Platform</strong>). The Platform is used by health practices,
          health practitioners and patients (<strong>you, your</strong>) to
          match patients with health practitioners. This Privacy Policy extends
          to any associated website or app, and any services provided by Katina,
          or any employee or officeholder of Katina.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          From time to time, we may revise or update this Privacy Policy, or our
          information handling practices. If we do so, the revised Privacy
          Policy will be published on our website, or otherwise provided to you
          when we collect personal or sensitive information from you.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We may collect personal or sensitive information in order to conduct
          our business, to provide and market our Platform and services and to
          meet our legal obligations. It may be used to match patients with
          psychologists on our Platform to assist with the overall assessment,
          diagnosis and treatment of patients. It may also be used to
          effectively communicate and provide information, as well as to provide
          a relevant and informed service. By engaging the services of the
          Platform, utilising the website, or by providing any information to
          us, you consent to the collection, use and disclosure of your
          information as set out in this Privacy Policy.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            The types of personal information we collect and hold
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          The types of personal information we may collect and store on the
          Platform or otherwise includes (but is not limited to) personal
          information about:
        </span>
      </p>
      <p style='margin-right:5.25pt;margin-left:41.25pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;margin-top:0cm;margin-bottom:.0001pt;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            your name, date of birth, address, contact telephone number and
            other contact details such as your email address;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            your payment information or payment information of a third party
            involved in the funding of your appointments or payment arrangements
            (such as credit card or bank account details);
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            health fund membership information, where applicable;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            Medicare details or DVA number, where applicable;&nbsp;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            Sensitive information as set out below; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            details of your use of our services.
          </span>
        </li>
      </ul>
      <p style='margin-right:5.25pt;margin-left:41.25pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;margin-top:0cm;margin-bottom:.0001pt;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          You are not obliged to provide personal information to us. However, in
          many cases, if you do not provide personal information to us, we may
          not be able to provide the relevant services of the Platform.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          In some circumstances, you may provide to us, and we may collect from
          you, personal information about a third party. Where you provide the
          personal information of a third party, you must ensure that the third
          party is aware of this Privacy Policy, understands it and agrees to
          accept it. If you are providing us with personal or sensitive
          information on behalf of a person who is under the age of 18 that you
          are the parent or guardian of, you understand that you are consenting
          to this Privacy Policy on their behalf as their legal guardian.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
          &nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
          We may also collect information from a third party if you have
          provided them with consent under their own privacy policies.
          Information collected may include (without limitation):
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;vertical-align:baseline;'>
          <span style="font-size:15px;border:none windowtext 1.0pt;padding:0cm;">
            health insurance provider details or Medicare details;&nbsp;
          </span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:#666666;vertical-align:baseline;'>
          <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
            other relevant information from third parties.
          </span>
        </li>
      </ul>
      <p style='margin-right:0cm;margin-left:36.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;vertical-align:baseline;'>
        <span style="font-size:15px;color:#666666;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;background:white;">
          We may collect health information from a third party or a publicly
          available source, but only if you consent to such collection or would
          reasonably expect us to collect such health information from that
          third party, or if collection is necessary to provide you with the
          necessary health care.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;vertical-align:baseline;'>
        <span style="font-size:15px;color:#666666;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          If it is necessary to do so, or if you decide to provide it, we may
          also collect sensitive information (
        </span>
        <strong>
          <span style="font-size:15px;color:black;">Sensitive Information</span>
        </strong>
        <span style="font-size:15px;color:black;">
          ) from you. Sensitive Information includes but is not limited to
          information about or pertaining to your;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:90.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <ul style="list-style-type: disc;">
        <li>
          <span style="font-size:11.0pt;">health;</span>
        </li>
        <li>
          <span style="font-size:11.0pt;">gender;&nbsp;</span>
        </li>
        <li>
          <span style="font-size:11.0pt;">sexual orientation;&nbsp;</span>
        </li>
        <li>
          <span style="font-size:11.0pt;">religious beliefs</span>
          <strong>
            <span style="font-family:Calibri;">;</span>
          </strong>
        </li>
        <li>
          <span style="font-size:11.0pt;">biography;</span>
        </li>
        <li>
          <span style="font-size:11.0pt;">socioeconomic status;</span>
        </li>
        <li>
          <span style="font-size:11.0pt;">
            medical, health or clinical information and records;&nbsp;
          </span>
        </li>
      </ul>
      <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;margin-right:5.25pt;text-align:justify;vertical-align:baseline;'>
          <span style="font-size:15px;">
            medical history pursuant to forms on the Platform you may be
            requested to complete;
          </span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;margin-right:5.25pt;text-align:justify;vertical-align:baseline;'>
          <span style="font-size:15px;">ethnicity</span>
          <span style="font-size:15px;">; or</span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;margin-right:5.25pt;text-align:justify;vertical-align:baseline;'>
          <span style="font-size:15px;">
            other personal, clinical or health related information, reasonably
            required by us to provide our services to you.
          </span>
        </li>
      </ul>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          We will only collect Sensitive Information from you if you agree to
          provide it to us. We will only use Sensitive Information in accordance
          with the Privacy Act and for the purposes for which it is
          provided,&nbsp;
        </span>
        <span style="font-size:15px;">
          for example to provide it to a health practitioner
        </span>
        <span style="font-size:15px;color:black;">
          . Once it is no longer required by us for the purposes of providing
          our services to you, or for any other purpose to which you have
          consented, any Sensitive Information that we collect will be
          automatically de-identified and will remain so unless you elect to
          disclose it to us or another party.&nbsp;
        </span>
        <span style="font-size:15px;color:black;">
          For example, we may collect information about your health and
          treatment from other medical professionals such as specialists or
          general medical practitioners
        </span>
        <span style="font-size:15px;color:black;">.&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            How personal information is collected and held by us
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We collect personal information in the following ways:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            when you complete a signed client form;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            when you submit personal information to the Platform (such as when
            you send us a message or fill out an online form, whether through
            the website or via social media);
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            in person, for example, when you engage with our employees, agents,
            or customer service representatives; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            in the course of providing services to you.
          </span>
        </li>
      </ul>
      <p style='margin-right:5.25pt;margin-left:41.25pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;margin-top:0cm;margin-bottom:.0001pt;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We store personal information in computer storage facilities and
          paper-based files. We take steps to protect your personal information
          against loss, unauthorised access, use modification or disclosure.
          Some examples of the steps we take to protect your personal
          information include:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            ensuring there are suitable password protection measures and access
            privileges in place to monitor and control access to our IT systems;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            imposing restrictions on physical access to paper files;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            requiring any third parties engaged by us to provide appropriate
            assurances to handle your personal information in a manner
            consistent with Australian law; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            taking reasonable steps to destroy or de-identify personal
            information after we no longer need it for our business or to comply
            with the law.
          </span>
        </li>
      </ul>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">
            Security of your information
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          We are committed to ensuring that the information you provide to us is
          secure. In order to prevent unauthorised access or disclosure, we have
          put in place suitable physical, electronic and managerial procedures
          to safeguard and secure personal and Sensitive Information and protect
          it from misuse, interference, loss and unauthorised access,
          modification and disclosure.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:72.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          The transmission and exchange of information is carried out at your
          own risk. We cannot guarantee the security of any information that you
          transmit to us or receive from us. Although we take measures to
          safeguard against unauthorised disclosures of information, we cannot
          assure you that personal information that we collect will not be
          disclosed in a manner that is inconsistent with this Privacy Policy.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:72.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          All information received digitally is maintained on secure servers in
          encrypted format. Access to stored information is protected by
          multi-layered security controls including firewalls, encryption,
          role-based access controls, and passwords.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            The purposes for which we collect, hold, use and disclose personal
            information
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We collect, hold, use and disclose personal information for a variety
          of business purposes including:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            to provide the services you have requested from us;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to improve our business and services;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to promote our business to you;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to market our other services to you;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to handle and respond to your enquiries, complaints or concerns; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to provide personal information to third parties as set out in this
            Privacy Policy.&nbsp;
          </span>
        </li>
      </ul>
      <p style='margin-right:5.25pt;margin-left:41.25pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;margin-top:0cm;margin-bottom:.0001pt;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">
          At our Platform, we use health information for the purpose of
          providing you with our services. We only use your information for
          other purposes if:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="margin-bottom:0cm;margin-top:0cm;" type="disc">
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;background:white;'>
          <span style="font-size:15px;">You have consented;</span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;background:white;'>
          <span style="font-size:15px;">
            You would reasonably expect your information to be used for that
            purpose.&nbsp;
          </span>
          <span style="font-size:15px;">
            For example, the Platform may disclose your health information to
            another health service provider for the purpose of providing you
            with health care;&nbsp;
          </span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;background:white;'>
          <span style="font-size:15px;">
            A failure in disclosing the information would result in the
            reasonable belief of the treating psychologist or practitioner that
            you or another individual would be at a serious risk to life, health
            or safety;
          </span>
          <span style="font-size:15px;">&nbsp;or</span>
        </li>
        <li style='margin:0cm;font-size:16px;font-family:"Calibri",sans-serif;color:black;background:white;'>
          <span style="font-size:15px;">
            Your information is required or authorised by law.
          </span>
        </li>
      </ul>
      <p style='margin-right:0cm;margin-left:36.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">
          We use the information collected, both personal and Sensitive, for the
          purposes of providing you with health care or for purposes which are
          directly related to providing you with health care.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">
          In addition, personal information is used to administer your invoicing
          for our services and credit card&nbsp;
        </span>
        <span style="font-size:15px;color:black;">payments.</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">Direct marketing</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We may also collect, hold, use and disclose your personal information
          to:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            notify you about the details of new services offered by us;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            send you our newsletters and other marketing publications;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            administer our databases for client service, marketing and financial
            accounting purposes; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            to comply with our legal requirements regarding the collection and
            retention of information concerning the services that we provide.
          </span>
        </li>
      </ul>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style="font-size:15px;color:black;">
          <br />
          >&nbsp;We may use or disclose your personal information to let you
          know about our new services and how they may better serve your
          needs.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          If you do not wish to disclose your personal information for the
          purpose of direct marketing or you would like to opt-out of receiving
          direct marketing communications, you can do so by contacting us using
          the contact details set out in this Privacy Policy, or by following
          the instructions to unsubscribe which are contained in a communication
          that you may receive from us from time to time.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
            &nbsp;
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
            Third Party Service Providers
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We may disclose your personal information to third parties who work
          with us to provide the services, or to promote, market or improve the
          services that we provide, including:
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <ul style="list-style-type: disc;margin-left:31.25px;">
        <li>
          <span style='font-family:"Times New Roman";'>
            providers of customer relations management database services and
            marketing database services;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            marketing consultants, promotion companies and website hosts;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            suppliers and subcontractors;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            referring practices or practitioners;
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            partnered businesses; and
          </span>
        </li>
        <li>
          <span style='font-family:"Times New Roman";'>
            consultants, insurers, and professional advisers.
          </span>
        </li>
      </ul>
      <p style='margin-right:5.25pt;margin-left:41.25pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;margin-top:0cm;margin-bottom:.0001pt;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We may also combine your personal information with information
          available from other sources, including the entities mentioned above,
          to help us provide better services to you.
        </span>
        <span style="font-size:15px;color:black;">
          &nbsp;Personal information is only supplied to a third party when it
          is required for the delivery of our services.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          Where we do share information with third parties, we require that
          there are contracts in place that only allow use and disclosure of
          personal information to provide the service and that protect your
          personal information in accordance with Australian law. Otherwise, we
          will disclose personal information to others if you&apos;ve given us
          permission, or if the disclosure relates to the main purpose for which
          we collected the information, and you would reasonably expect us to do
          so.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            How we handle requests to access your personal information
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          You have a right to request access to your personal information which
          we hold about you and to request its correction. You can make such a
          request by contacting us using the contact details set out in this
          Privacy Policy.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We will respond to any such request for access as soon as reasonably
          practicable. Where access is to be given, we will provide you with a
          copy or details of your personal information in the manner requested
          by you where it is reasonable and practicable to do so.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We will not charge you a fee for making a request to access your
          personal information. However, we may charge you a reasonable fee for
          giving you access to your personal information.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          In some cases, we may refuse to give you access to the information you
          have requested or only give you access to certain information. If we
          do this, we will provide you with a written statement setting out our
          reasons for refusal, except where it would be unreasonable to do so.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            How we handle requests to correct your personal information
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We will take such steps (if any) as are reasonable in the
          circumstances to make sure that the personal information we collect,
          use or disclose is accurate, complete, up to date and relevant.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;background:white;'>
        <span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>
          &nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          If you believe the personal information we hold about you is
          inaccurate, irrelevant, out of date or incomplete, you can ask us to
          update or correct it. To do so, please contact us using the contact
          details listed in this Privacy Policy.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">Website</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          When you visit our website, we may collect certain information such as
          browser type, operating system, website visited immediately before
          coming to our site, as well as other similar information. This
          information is used in an aggregated manner to analyse how our website
          is used to help us improve our services.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:72.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          We may from time to time use cookies on our website. Cookies are very
          small files which a website uses to identify you when you come back to
          the site and to store details about your use of the site. Cookies are
          not malicious programs that access or damage your computer. Most web
          browsers automatically accept cookies, but you can choose to reject
          cookies by changing your browser settings. However, this may prevent
          you from taking full advantage of our website. Our website may from
          time to time use cookies to analyse website traffic and help us
          provide a better visitor experience. In addition, cookies may be used
          to serve relevant ads to website visitors through third party services
          such as Google AdWords. These ads may appear on the website or other
          websites you visit.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:72.0pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          Our site may, from time to time, have links to other websites not
          owned or controlled by us. These links are meant for your convenience
          only. Links to third party websites do not constitute our sponsorship,
          endorsement or approval of such websites. Please be aware that we are
          not responsible for the privacy practices of other websites.&nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          Like many site operators, we collect information that your browser
          sends whenever you visit our website (<strong>Log Data</strong>).
          &nbsp; This Log Data may include information such as your
          computer&apos;s Internet Protocol (<strong>IP</strong>) address,
          browser type, browser version, the pages of our website that you
          visit, the time and date of your visit, the time spent on those pages
          and other statistics.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:39.3pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          We may utilise various web analytics services and providers, including
          but not limited to Google Analytics, a type of web analytics service
          provided by Google, Inc. (<strong>Google</strong>). Such platforms and
          services use cookies and other data collections methods to help us
          analyse how users interact with use our Platform, evaluate the
          performance of our content, and enhance user experience. Although
          Google Analytics, or other analytics platforms, may record data such
          as your geographical location, device, internet browser and operating
          system, none of this information personally identifies you to us.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:39.3pt;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          Where we use Google Analytics, the information generated by the cookie
          about your use of the website will be transmitted to and stored by
          Google on servers in your country of residence. Google will use the
          information on behalf of us for the purpose of evaluating your use of
          the website, compiling reports on activity for us and providing us
          with other services relating to activity and internet usage. The IP
          address that your browser conveys within the scope of Google Analytics
          will not be associated with any other data held by Google. You may
          refuse the use of these cookies via the settings in your browser. You
          can also opt out of being tracked by Google Analytics by downloading
          and installing Google Analytics Opt-out Browser Add-on for your
          current web browser:&nbsp;
        </span>
        <a href="http://tools.google.com/dlpage/gaoptout?hl+en">
          <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;text-decoration:none;">
            http://tools.google.com/dlpage/gaoptout?hl+en
          </span>
        </a>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;border:none windowtext 1.0pt;padding:0cm;">
          &nbsp;
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;'>
        <span style="font-size:15px;color:black;">
          Where an alternative analytics platform or service is utilised by us,
          storage of your use of the website will be in accordance with the
          relevant platform&rsquo;s privacy policy.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <h2 style='margin-right:0cm;margin-left:0cm;font-size:24px;font-family:"Times New Roman",serif;font-weight:bold;margin:0cm;background:white;'>
        <span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>
          Notifiable data breaches scheme
        </span>
      </h2>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Times New Roman",serif;margin:0cm;'>
        <span style='font-size:15px;font-family:"Calibri",sans-serif;color:black;'>
          In the event of any unauthorised access or unauthorised disclosure or
          loss of your personal or Sensitive Information that is likely to
          result in serious harm to you or to other individuals, we will
          investigate and notify you and the Office of the Australian
          Information Commissioner in accordance with the Privacy Act.
        </span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">&nbsp;</span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <strong>
          <span style="font-size:15px;color:black;">
            How to contact us or make a complaint
          </span>
        </strong>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          If you have any questions about this Privacy Policy, if you wish to
          correct or update information we hold about you or if you wish to
          request access or correction of your personal or Sensitive Information
          or make a complaint about a breach by us of our privacy obligations
          (including the way we have collected, disclosed or used your personal
          information), please contact us on{" "}
          <span style="background:aqua;">
            <br />
            >&nbsp;
          </span>
        </span>
        <span style="font-size:15px;background:aqua;">admin@katina.com.au</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">&nbsp;</span>
      </p>
      <p style='margin-right:0cm;margin-left:0cm;font-size:16px;font-family:"Calibri",sans-serif;margin:0cm;text-align:justify;vertical-align:baseline;'>
        <span style="font-size:15px;color:black;">
          We will acknowledge and investigate any complaint about the way we
          manage personal or Sensitive Information as soon as practicable. We
          will take reasonable steps to remedy any failure to comply with our
          privacy obligations.&nbsp;
        </span>
      </p>`)}
    </Article>
  );
}
