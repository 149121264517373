import { Account } from "views/account";
import { Profile } from "views/account/profile";
import { Billing } from "views/account/billing";
import { Upgrade } from "views/account/upgrade";
import { Users } from "views/account/users";
import { Password } from "views/account/password";
import { TwoFA } from "views/account/2fa";
import { APIKeyList } from "views/account/apikey/list";
import { APIKeyEditor } from "views/account/apikey/edit";
import { ManagePractice } from "views/account/practice";
import { ConfirmPsychProfile } from "views/account/confirm_psych";
import { ManagePsychProfile } from "views/account/psych_profile";
import { ClinicianProfile } from "views/account/clinician_profile";
import { ClientArchive } from "views/account/client_archive";
import { PsychLeaderboard } from "views/account/psych_leaderboard";
import { ManageTemplates } from "views/account/templates";
import { PractitionerSearch } from "views/account/search";
import { CustomizeAccount } from "views/account/customize";
import { Exclusions } from "views/account/exclusions";
import Coreplus from "views/account/coreplus";
import { TemplateSettings } from "views/account/template_settings";

const Routes = [
  {
    path: "/account",
    view: Account,
    layout: "app",
    permission: "user",
    title: "Your Account",
  },
  {
    path: "/account/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Your Profile",
  },
  {
    path: "/account/password",
    view: Password,
    layout: "app",
    permission: "user",
    title: "Change Password",
  },
  {
    path: "/account/2fa",
    view: TwoFA,
    layout: "app",
    permission: "user",
    title: "Manage 2FA",
  },
  {
    path: "/account/billing",
    view: Billing,
    layout: "app",
    permission: "owner",
    title: "Billing",
  },
  {
    path: "/account/upgrade",
    view: Upgrade,
    layout: "app",
    permission: "owner",
    title: "Upgrade",
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "admin",
    title: "Users",
  },
  {
    path: "/account/practice",
    view: ManagePractice,
    layout: "app",
    permission: "admin",
    title: "Practice",
  },
  {
    path: "/account/client/templates",
    view: ManageTemplates,
    layout: "app",
    permission: "admin",
    title: "Templates",
  },
  {
    path: "/account/template/:id",
    view: TemplateSettings,
    layout: "app",
    permission: "admin",
    title: "Templates ",
  },
  {
    path: "/account/psych/confirm",
    view: ConfirmPsychProfile,
    layout: "app",
    permission: "user",
    title: "Confirm Psych Profile",
  },
  {
    path: "/account/psych/profile",
    view: ManagePsychProfile,
    layout: "app",
    permission: "user",
    title: "Psych Profile",
  },
  {
    path: "/account/apikeys",
    view: APIKeyList,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/apikeys/create",
    view: APIKeyEditor,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/search",
    view: PractitionerSearch,
    layout: "app",
    permission: "admin",
    title: "Practitioner Search",
  },
  {
    path: "/account/apikeys/edit",
    view: APIKeyEditor,
    layout: "app",
    permission: "developer",
    title: "API Keys",
  },
  {
    path: "/account/user/:id",
    view: ClinicianProfile,
    layout: "app",
    permission: "admin",
    title: "Clinician Profile",
  },
  {
    path: "/account/client/archive",
    view: ClientArchive,
    layout: "app",
    permission: "admin",
    title: "Client Archive",
  },
  {
    path: "/account/leaderboard/:id",
    view: PsychLeaderboard,
    permission: "admin",
    layout: "app",
    title: "Clinican Leaderboard",
  },
  {
    path: "/account/customise",
    view: CustomizeAccount,
    permission: "admin",
    layout: "app",
    title: "Customize Account",
  },
  {
    path: "/account/exclusions",
    view: Exclusions,
    permission: "admin",
    layout: "app",
    title: "Remove Categories",
  },
  {
    path: "/account/coreplus",
    view: Coreplus,
    layout: "app",
    permission: "admin",
    title: "Coreplus Integration",
  }
];

export default Routes;
