import React, { useCallback, useEffect, useContext } from "react";
import { Form, Card } from "components/lib";
import { useParams } from "react-router-dom";

export function ClientUpdate(props) {
  const { id } = useParams();
  return (
    <>
      <Card restrictWidth>
        <Form
          inputs={{
            created_at: {
              label: "Created Date",
              type: "date",
              required: true,
              errorMessage: "Please enter a date",
            },
            id: {
              type: "hidden",
              value: id,
            },
          }}
          method="PATCH"
          buttonText="Update"
          url="/api/client/created_at"
        />
      </Card>
    </>
  );
}
