/***
 *
 *   TERMS & CONDITIONS
 *   Boilerplate terms & conditions – replace the values
 *   inside [] with your own text
 *
 **********/

import React from "react";
import { Article } from "components/lib";
import parse from "html-react-parser";

export function Terms(props) {
  return (
    <Article>
      {parse(`<table cellpadding="0" cellspacing="0">

 <p>
The Katina Online Platform is not providing a health service, and we do not provide any healthcare or medical services. The Platform is designed by the purposes of matching patients with Health Practitioners based on the inputs of the patient and Health Practitioner. We accept no liability for the health service provided by a Health Practitioner to a patient, whether or not that patient was matched with the Health Practitioner through the Platform.
</p>

<br clear="ALL"/>
<strong>Patient Terms and Conditions</strong>
<p>
    <strong>1</strong> <strong>Overview</strong>
</p>
<p>
    1.1                Katina Online Pty  Ltd ACN 667 690 655 (<strong>we, us,
    our</strong>) operates a platform called Katina  Online (<strong>Platform</strong>), 
    a platform developed and owned exclusively by us. The  Platform matches you
    with a suitably qualified health practitioner (<strong>Health Practitioner</strong>) 
    based on the inputs provided by you to the Platform.<strong></strong>These
    Terms and Conditions (<strong>Terms and Conditions</strong>) record an
    agreement between  you (<strong>you, your</strong>), as the patient and us
    for the purpose of your use of  the Platform.
</p>
<p>
    1.2                The Platform is  not providing a health service, and we
    do not provide any healthcare or medical  services. The Platform is designed
    for the purpose of matching patients with  appropriate Health Practitioners
    and scheduling appointments between them based  on the inputs of the Health
    Practitioners and patients. We are in no way liable  for any health services
    provided to you by any such Health Practitioner,  whether or not you were
    matched with that Health Practitioner through the  Platform.
</p>
<p>
    <strong>2</strong> <strong>Acceptance  of Terms and Conditions</strong>
</p>
<p>
    2.1                By using the Platform,  you are accepting these Terms and
    Conditions and agree to be bound by them. <strong></strong>
</p>
<p>
    2.2                We reserve the  right to make changes to these Terms and
    Conditions from time to time without  notice. <strong></strong>
</p>
<p>
    <strong>3</strong> <strong>Use</strong>
</p>
<p>
    <a name="_Ref80283335">
        3.1                To use the Platform,  you may be required to supply
        the following information required by a Health Practitioner:
    </a>
    <strong></strong>
</p>
<p>
    (a)                 Your full name; <strong></strong>
</p>
<p>
    (b)                 Your residential address; <strong></strong>
</p>
<p>
    (c)                 Your email address; <strong></strong>
</p>
<p>
    (d)                 Your phone number; <strong></strong>
</p>
<p>
    (e)                 Your date of birth; <strong></strong>
</p>
<p>
    (f)                  Your personal and sensitive health records;
    <strong>
    </strong>
</p>
<p>
    (g)                 Any other information reasonably required to utilise the
    Platform. <strong></strong>
</p>
<p>
    (<strong>User Data</strong>).
</p>
<p>
    <a name="_Ref80353288">
        3.2                Our use of any  personal or sensitive information
        provided within the User Data will be in  accordance with our Privacy
        Policy available at [insert link to privacy policy].
    </a>
    <strong></strong>
</p>
<p>
    3.3                You must not modify, adapt or hack the Platform or
    modify another website or software so as to falsely imply that it is
    associated  with the Platform or us. You<strong></strong>must use the
    Platform for its intended  purpose.  You may not use the Platform for  any
    illegal or unauthorised purpose, including the transmission of  malware.
    You must not use the Platform to  violate any laws in any jurisdiction,
    including but not limited to copyright  laws. For the avoidance of doubt,
    you must not infringe upon the copyrights of  any other person in using the
    Platform. <strong></strong>
</p>
<p>
    <strong>4</strong> <strong>Intellectual Property</strong>
</p>
<p>
    4.1                Your use of the Platform is pursuant to a non-exclusive
    license between you and us which is granted subject to these Terms and
    Conditions. Nothing in these Terms and Conditions or otherwise creates an
    assignment of any rights in the Platform beyond the ability to use it for
    its  intended use. <strong></strong>
</p>
<p>
    4.2                Nothing in these Terms and Conditions or otherwise
    creates the right for you to sublicense the Platform. <strong></strong>
</p>
<p>
    4.3                You undertake not to reverse engineer, replicate, or
    otherwise copy the Platform. We reserve all our rights to seek compensation,
    damages, injunctions, or any other remedy available to us at law if any
    attempt  to do so, whether successful or unsuccessful, is made by you or any
    of your  affiliates. <strong></strong>
</p>
<p>
    4.4                You must not reproduce, duplicate, copy, sell, resell  or
    exploit any portion of the Platform, or access to it, without our prior
    express written permission. We claim copyright and all other intellectual
    property rights over the Platform, its other services and reserve all of our
    rights. <strong></strong>
</p>
<p>
    4.5                All intellectual property and ownership rights
    subsisting in the Platform (including the user interface and the software
    comprising it), the data derived from use of the Platform, and any other
    documentation, information or materials that are supplied by us to you,
    remain our  or our third party licensors’ exclusive property.  For the
    avoidance of doubt, nothing in this  agreement assigns any ownership rights
    to you.
</p>
<p>
    <strong>5</strong> <strong>Operation</strong>
</p>
<p>
    5.1                You warrant that you will comply fully with all Platform
    operating instructions. Any failure to comply fully with operating
    instructions  may result in the sub-par or faulty performance of the
    Platform. <strong></strong>
</p>
<p>
    5.2                We accept no liability, and you release us from any  such
    liability, associated with faults or errors caused by your non-compliance
    with all operating instructions. <strong></strong>
</p>
<p>
    <strong>6</strong> <strong>Warranties</strong>
</p>
<p>
    6.1                The Platform is made available to you on an “as is”
    basis. We make no warranties, representations or conditions of any kind,
    express, statutory or implied as to: <strong></strong>
</p>
<p>
    (a)                 The operation and functionality; <strong></strong>
</p>
<p>
    (b)                 the accuracy, integrity, completeness, quality,
    legality, usefulness, safety and intellectual property rights of any of the
    software content; and/or <strong></strong>
</p>
<p>
    (c)                 the features associated with the Platform or its
    content. <strong></strong>
</p>
<p>
    6.2                The Platform schedules appointments with Health
    Practitioners based on your inputs and the inputs of the Health
    Practitioner.  We make no warranty or guarantee that the resulting match
    between patient and  Health Practitioner will be suitable, including but not
    limited to in  circumstances where you or the Health Practitioner have
    provided inaccurate or  incomplete information. <strong></strong>
</p>
<p>
    <strong>7</strong> <strong>Limitation of liability</strong>
</p>
<p>
    7.1                Your use of the Platform is at your own discretion and
    risk, and you will be solely responsible for any resulting loss or damage,
    including but not limited to, any loss of data or damage to your computer(s)
    or  networks from viruses that may be downloaded to your computer in the
    course of  using the Platform. Some jurisdictions do not allow the exclusion
    of certain  warranties, the limitation or exclusion of implied warranties,
    or limitations  on how long an implied warranty may last, so the above
    limitations may not  apply to you. If you reside in such a jurisdiction, the
    above limitations shall  apply to you to the fullest extent permitted under
    applicable law. <strong></strong>
</p>
<p>
    <strong>8</strong> <strong>General</strong>
</p>
<p>
    8.1                To the extent permitted by law, we reserve the right to
    create, collect and / or store any metadata resulting from your use of the
    Platform.  To the extent permitted by law, we may use metadata for a variety
    of functions  including (but not limited to) conducting investigations,
    helping us manage and  administer the Platform, for purposes of search
    engine optimisation and to  drive traffic to the Platform.  To the  extent
    permitted by law, we reserve the right to share your information  (including
    metadata) with government agencies and authorities to the extent  this is
    required or requested of us. <strong></strong>
</p>
<p>
    8.2                Our failure to exercise or enforce any right or
    provision of these Terms and Conditions shall not constitute a waiver of
    such  right or provision. The Terms and Conditions constitutes the entire
    agreement between  you and us and govern your use of the Platform,
    superseding any prior  agreements between you and us. <strong></strong>
</p>
<p>
    8.3                Any part of these Terms and Conditions that is invalid,
    prohibited or not enforceable will not void the balance of the Terms and
    Conditions.  All other parts will be  enforced without regard to the invalid
    provision and continue to be valid in  accordance with their terms.
    <strong>
    </strong>
</p>
<p>
    8.4                We make no representations or warranties that the
    Platform  complies with the laws (including intellectual property laws) of
    any country  outside of Australia. <strong></strong>
</p>
<p>
    8.5                Any queries about the Terms and Conditions should be
    sent to us by email at [insert  email]. <strong></strong>
</p>
<p>
    8.6                These Terms and Conditions are governed by the laws of
    Victoria  in Australia. Disputes arising from your use of the Platform are
    exclusively  subject to the jurisdiction of the courts of Victoria.
    <strong>
    </strong>
</p>
`)}
    </Article>
  );
}
