import React, { Fragment, useCallback } from "react";
import "survey-core/defaultV2.css";
import { useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { useAPI, AccountNav, Card } from "components/lib";
import psychFormJson from "./psych_form_json";
import Axios from "axios";
import "survey-core/defaultV2.css";
import { StylesManager, Model } from "survey-core";

export function ClinicianProfile(props) {
  const { id } = useParams();
  const psychProfile = useAPI(`/api/user/psych_profile/${id}`);
  const locations = useAPI("/api/practice");

  StylesManager.applyTheme("defaultV2");
  psychFormJson.pages[1].elements[26].templateElements[0].choices =
    locations?.data?.map((l) => {
      return { value: l.id, text: l.name };
    });

  const surveyComplete = useCallback((sender) => {
    console.log("sender.data", sender);
    saveSurveyResults(`/api/user/psych_profile/${id}`, sender.data);
  }, []);

  const survey = new Model(psychFormJson);
  survey.focusFirstQuestionAutomatic = true;
  survey.onComplete.add(surveyComplete);
  // survey.mode = "display";
  survey.data = psychProfile?.data;

  function saveSurveyResults(url, json) {
    console.log("json", json);
    Axios.post(url, json);
  }

  return (
    <Fragment>
      <AccountNav />
      <Card>
        {!psychProfile?.loading && !locations?.loading && (
          <Survey model={survey} />
        )}
      </Card>
    </Fragment>
  );
}
