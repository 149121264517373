import { Fragment, useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  useAPI,
  TitleRow,
  Button,
  ViewContext,
} from "components/lib";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";

// 1. existing templates
// 2. create new template
// 3. success message
// 4. edit template
// 5. delete template

export function ManageTemplates(props) {
  // const [buttonText, setButtonText] = useState("Create");
  // const [actionButtonText, setActionButtonText] = useState("Create");
  // const [id, setId] = useState("");
  // const [showForm, setShowForm] = useState(false);
  // const [body, setBody] = useState("");
  // const [subject, setSubject] = useState("");
  // const [name, setName] = useState("");
  // const [replyTo, setReplyTo] = useState("");

  const templates = useAPI("/api/template");
  const context = useContext(ViewContext);




  // const editTemplate = (row) => {
  //   console.log("edit template", row);
  //   setName(row.name);
  //   setSubject(row.subject);
  //   setBody(row.body);
  //   setId(row.id);
  //   setReplyTo(row.reply_to);
  //   setButtonText("Update");
  //   setActionButtonText("Update");
  //   setShowForm(true);
  // };
  // const deleteTemplate = (row) => {
  //   console.log("delete template", row);
  //   axios.delete(`/api/template/${row.id}`).then((res) => {
  //     console.log("res", res);
  //     context.notification.show(
  //       "Template deleted successfully",
  //       "success",
  //       true,
  //       "toast",
  //       "check"
  //     );
  //   });
  //   window.location.reload();
  // };

  // const toggle = () => {
  //   console.log("toggle");
  //   showForm ? setShowForm(false) : setShowForm(true);
  //   showForm ? setButtonText("Create") : setButtonText("Hide");
  // };

  // const addOrUpdateTemplate = () => {
  //   console.log("add or update template");
  //   buttonText === "Hide" ? addTemplate() : updateTemplate();
  // };

  // const updateTemplate = async () => {
  //   console.log("update template");
  //   const data = name && subject && body && replyTo;

  //   if (!data) {
  //     context.notification.show(
  //       "Please enter all fields",
  //       "error",
  //       true,
  //       "toast",
  //       "error"
  //     );
  //   }

  //   const template = {
  //     name,
  //     subject,
  //     body,
  //     reply_to: replyTo,
  //   };

  //   console.log("template", template);

  //   await axios.patch(`/api/template/${id}`, template).then((res) => {
  //     console.log("res", res);
  //     context.notification.show(
  //       "Template updated successfully",
  //       "success",
  //       true,
  //       "toast",
  //       "check"
  //     );
  //     setShowForm(false);
  //   });
  //   window.location.reload();
  // };

  // const addTemplate = () => {
  //   console.log("add template");
  //   const data = name && subject && body && replyTo;

  //   if (!data) {
  //     context.notification.show(
  //       "Please enter all fields",
  //       "error",
  //       true,
  //       "toast",
  //       "error"
  //     );
  //   }

  //   const template = {
  //     name,
  //     subject,
  //     body,
  //     reply_to: replyTo,
  //   };

  //   console.log("template", template);
  //   axios.post("/api/template", template).then((res) => {
  //     console.log("res", res);
  //     context.notification.show(
  //       "Template created successfully",
  //       "success",
  //       true,
  //       "toast",
  //       "check"
  //     );
  //     setShowForm(false);
  //   });
  //   window.location.reload();
  // };

  const createTemplate= () => {
    context.modal.show(
      {
        title: "Create a Template",
        form: {
          name: {
            label: "Template Name",
            type: "text",
            required: true,
          },
        },
        buttonText: "Create",
        url: "/api/template",
        method: "POST",
      },
      (form, res) => {
        console.log("form and res", form, res);
        window.location.href = `/account/template/${res.id}`;
      }
    );
  };

  const editTemplate = (row) => {
    window.location.href = `/account/template/${row.id}`;
  }

  const deleteTemplate = (row) => {
    // axios.delete(`/api/template/${row.id}`).then((res) => {
    //   console.log("res", res);
    //   context.notification.show(
    //     "Template deleted successfully",
    //     "success",
    //     true,
    //     "toast",
    //     "check"
    //   );
    // });
    context.modal.show(
      {
        title: "Are you sure you want to delete this template?",
        buttonText: "Delete",
        url: `/api/template/${row.id}`,
        form: {
          name: {
            label: "Template Name",
            type: "hidden",
            // required: true,
            hidden: true,
          },
        },
        method: "DELETE",
      },
      (form, res) => {
        console.log("form and res", form, res);
        window.location.reload();
      }
    );
    // window.location.reload();
  }


  return (
    <Fragment>
      <TitleRow title="Manage Templates">
        <Button small text={"Create"} action={createTemplate} />
      </TitleRow>
      {/* {showForm && (
        <Card>
          <h3>Name</h3>
          <input
            className={"border border-gray-300 p-2 rounded mt-2 w-full"}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <br />
          <h3>Reply To</h3>
          <input
            className={"border border-gray-300 p-2 rounded mt-2 w-full"}
            type="text"
            value={replyTo}
            onChange={(e) => setReplyTo(e.target.value)}
          />
          <br />
          <h3>Subject</h3>
          <input
            className={"border border-gray-300 p-2 rounded mt-2 w-full"}
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <br />
          <h3>Body</h3>
          <ReactQuill value={body} onChange={setBody} />
          <br />
          <br />
          <Button action={addOrUpdateTemplate} text={actionButtonText} />
        </Card>
      )} */}
      <Card>
        <Table
          search
          className="restrict-width"
          data={templates?.data}
          loading={templates?.loading}
          show={["name", "subject", "reply_to"]}
          hide={["id"]}
          actions={{
            edit: editTemplate,
            delete: deleteTemplate,
          }}
        />
      </Card>
    </Fragment>
  );
}
