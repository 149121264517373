import { useAPI, Loader, AccountNav, Button } from "components/lib";
import React from "react";
import MaterialReactTable from "material-react-table";
import { useMemo } from "react";
import { useEffect, useState } from "react";
import Axios from "axios";

export function ClientArchive(props) {
  // get all deleted clients
  const clientsAPI = useAPI("/api/client/archive");
  const psych_usersAPI = useAPI("/api/user/psych_profiles");
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (clientsAPI?.data && psych_usersAPI?.data) {
      const updatedClients = clientsAPI.data.map((client) => {
        return {
          name: client.name,
          assigned_user:
            psych_usersAPI?.data?.find((u) => u.id === client.user_id)?.name ||
            "Unassigned",
          id: client.id,
          updated_at: client.updated_at,
        };
      });

      setClients(updatedClients);
    }
  }, [clientsAPI, psych_usersAPI]);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessorKey: "name",
      },
      {
        header: "Assigned To",
        accessorKey: "assigned_user",
      },
      {
        header: "id",
        accessorKey: "id",
      },
      {
        header: "Updated At",
        accessorKey: "updated_at",
        sortingFn: "datetime",
      },
    ],
    []
  );

  // if loading, return loader
  if (clients?.loading) return <Loader />;

  if (clients?.data && clients?.data?.length === 0) {
    return (
      <div>
        <AccountNav />
        <p>No clients found</p>
      </div>
    );
  }

  const unarchive = async (row) => {
    console.log("unarchive", row.row.original.id);
    const unarchiveAPI = Axios.patch(
      `/api/client/archive/${row.row.original.id}`
    );
    console.log("unarchiveAPI", unarchiveAPI);
    window.location.reload();
  };

  return (
    <div>
      <AccountNav />

      <MaterialReactTable
        columns={columns}
        data={clients}
        enableRowActions
        initialState={{ columnVisibility: { id: false } }}
        positionActionsColumn="last"
        renderRowActions={(row, index) => [
          <>
            <Button
              small
              color="blue"
              action={unarchive}
              params={row}
              text="Unarchive"
            ></Button>
          </>,
        ]}
      />
    </div>
  );
}
