import React, { Fragment } from "react";
import { Form, useAPI, Card, AccountNav } from "components/lib";
import { useNavigate } from "react-router-dom";

export function ConfirmPsychProfile(props) {
  const user = useAPI("/api/user");
  const navigate = useNavigate();

  return (
    <Fragment>
      <AccountNav />
      <Card restrictWidth title="Confirm" loading={user?.loading && user?.data}>
        {user?.data && (
          <Form
            inputs={{
              is_psych: {
                label: "Are you going to be seeing clients?",
                required: true,
                default: user?.data?.is_psych,
                type: "select",
                options: [
                  { value: true, label: "Yes" },
                  { value: false, label: "No" },
                ],
              },
            }}
            method="PATCH"
            url="/api/user"
            buttonText="Save"
            callback={(res) => {
              console.log('res', res)
              if (res?.data?.data?.is_psych === true ) {
                navigate("/account/psych/profile");
              } else {
                navigate("/dashboard");
              }
            }}
          />
        )}
      </Card>
    </Fragment>
  );
}
