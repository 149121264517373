import React, { useState } from "react";
import { Label, Error, Icon, ClassHelper } from "components/lib";
import ReactQuill from "react-quill";
import Style from "./input.tailwind.js";
import "react-quill/dist/quill.snow.css";

export function HtmlInput(props) {
  const [value, setValue] = useState(props.value || "");
  // <div className={Style.input}>
  <>
    <ReactQuill
      id={props.id}
      name={props.name}
      theme="snow"
      value={value}
      onChange={setValue}
    />
  </>;
  {
    /* </div>; */
  }
}
