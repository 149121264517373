import { useContext, useState, useEffect, useMemo } from "react";
import { ViewContext, Card, Button, useAPI } from "components/lib";
import MaterialReactTable from "material-react-table";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export function BulkEmails(props) {
  const location = useLocation();
  const [ids, setIds] = useState([]);
  const [clients, setClients] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const context = useContext(ViewContext);
  const [replyTo, setReplyTo] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const templates = useAPI("/api/template");
  const [options, setOptions] = useState([{ id: 1, name: "test-loca" }]);

  useEffect(() => {
    // Get query parameters from location object
    const queryParams = new URLSearchParams(location.search);
    console.log(queryParams.get("ids"));
    setIds(queryParams.get("ids").split(","));
  }, [location]);

  useEffect(() => {
    if (templates?.data && !templates?.loading) {
      console.log("templates", templates);
      setOptions(templates.data);
    }
  }, [templates]);

  useEffect(() => {
    const getTemplate = async () => {
      const template = await Axios.get(`/api/template/${selectedOption}`);
      console.log("template", template);
      setSubject(template.data.data[0].subject);
      setBody(template.data.data[0].body);
      setReplyTo(template.data.data[0].reply_to);
    };

    if (!selectedOption) return;
    console.log("selectedOption", selectedOption);
    const template = getTemplate();
  }, [selectedOption]);

  useEffect(() => {
    if (ids.length > 0) {
      ids.map((id) => {
        Axios.get(`/api/client/${id}`)
          .then((res) => {
            console.log(res.data);
            setClients((clients) => [...clients, res.data?.data?.[0]]);
          })
          .catch((err) => {
            console.log(err);
          });
      });
    }
  }, [ids]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleSend = async () => {
    const response = await Axios.post(`/api/utility/bulk`, {
      ids: ids,
      reply_to: replyTo,
      subject: subject,
      body: body,
    });
    console.log("response", response);
    context.notification.show(
      "Emails have been sent.",
      "success",
      true,
      "toast",
      "heart"
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorFn: (row) => {
          return row?.data?.email;
        },
        header: "Email",
      },
    ],
    []
  );

  return (
    <>
      <Card>
        <MaterialReactTable
          data={clients}
          columns={columns}
          loading={clients.length === 0}
          defaultPageSize={10}
          showPagination={true}
        />
      </Card>

      <Card restrictWidth>
        <h1 className="text-lg font-bold">Email Clients</h1>
        <h2>Select a template</h2>
        <div>
          <select
            value={selectedOption}
            onChange={handleOptionChange}
            className="border border-gray-300 p-2 rounded mt-2"
          >
            <option value="">Select an option</option>
            {options.map((o) => (
              <option key={o.id} value={o.id}>
                {o.name}
              </option>
            ))}
          </select>
        </div>
        <br />
        <h3>Reply To</h3>
        <input
          className={"border border-gray-300 p-2 rounded mt-2 w-full"}
          type="text"
          value={replyTo}
          onChange={(e) => setReplyTo(e.target.value)}
        />
        <h3>Subject</h3>
        <input
          className={"border border-gray-300 p-2 rounded mt-2 w-full"}
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
        <br />
        <h3>Body</h3>
        <ReactQuill value={body} onChange={setBody} />
        <br />
        <Button text="Send" action={handleSend} />
      </Card>
    </>
  );
}
