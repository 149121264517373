import React, { useEffect, useCallback, Fragment } from "react";
import { Survey } from "survey-react-ui";
import { useAPI, AccountNav, Card } from "components/lib";
import { StylesManager, Model } from "survey-core";
import "survey-core/defaultV2.css";
import Axios from "axios";

StylesManager.applyTheme("defaultV2");

function Coreplus() {
  const accountInfo = useAPI("/api/account");

  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    pages: [
      {
        name: "info",
        elements: [
          {
            type: "boolean",
            isRequired: true,
            name: "enabled",
            title: "Enable Coreplus Integration",
          },
          {
            type: "comment",
            name: "appAccessToken",
            title: "Coreplus App Access Token",
            description:
              "Follow the instructions in the Coreplus documentation to obtain an access token.",
            isRequired: true,
          }
        ]
      },
    ],
    showQuestionNumbers: "off",
  };
  const model = new Model(json);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.patch(url, json);
  }

  const updateCoreplusIntegrationSettings = useCallback((sender) => {
    saveSurveyResults("/api/account/coreplus", sender.data);
  });

  model.onComplete.add(updateCoreplusIntegrationSettings);

  if (accountInfo.loading || accountInfo.data === undefined) {
    return <div>Loading...</div>;
  }

  function setExistingCoreplusSettings() {
    console.log("accountInfo", accountInfo);  
    model.setValue(
      "enabled",
      accountInfo?.data?.settings?.coreplusIntegration?.enabled
    );
    model.setValue(
      "appAccessToken",
      accountInfo?.data?.settings?.coreplusIntegration?.appAccessToken
    );
  }

  setExistingCoreplusSettings();

  return (
    <Fragment>
      <AccountNav />
      <Card
        loading={accountInfo?.loading && accountInfo?.data}
      >
        {<Survey model={model} />}
      </Card>
    </Fragment>
  );
}

export default Coreplus;
