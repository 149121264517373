/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  AuthContext,
  Onboarding,
  Form,
  useAPI,
  Card,
  Table,
  Message,
} from "components/lib";
import Axios from "axios";

export function OnboardingView(props) {
  const context = useContext(AuthContext);
  const views = [
    {
      name: "Getting Started",
      description: `Welcome to Katina, ${context.user.name}!`,
      component: <Welcome />,
    },
  ];

  if (context.permission.owner) {
    views.push({
      name: "Name",
      description: "Please provide the name of your psychology organization.",
      component: <OrganisationName />,
    });

    views.push({
      name: "Add Locations",
      description:
        "Katina finds matches based on psychologist availability and your practice locations.",
      component: <AddLocations />,
    });

    views.push({
      name: "Invite Your Team",
      description: "Katina is most effective with everyone using it.",
      component: <InviteUsers />,
    });
  }
  if (context.user.duplicate_user) {
    views.unshift({
      name: "Important!",
      description: "",
      component: <DuplicateUser />,
    });
  }

  let redirectTo = "/dashboard";

  if (context.permission.user) {
    redirectTo = "/account/psych/confirm";
  } else if (context.permission.admin) {
    redirectTo = "/account/psych/profile";
  }

  return <Onboarding save onFinish={redirectTo} views={views} />;
}

function DuplicateUser() {
  return (
    <Message
      type="warning"
      title={`You already have an account`}
      text="We noticed you have already registered an account, so we used your original password to save you any confusion in the future."
    />
  );
}

function Welcome() {
  return (
    <Fragment>
      <p>
        Katina Online has been designed to help psychology practices manage
        their referrals more effectively. This means that, as a service, your
        organisation can spend less time managing referrals and more time
        providing high-quality services to your clients. Your clinicians will be
        able to create a profile that outlines their specific areas of
        expertise, availability, and location. This profile will then be used to
        match them with potential client referrals.
      </p>

      <p>
        <strong>Let’s get you set-up! </strong>
      </p>
    </Fragment>
  );
}

function InviteUsers(props) {
  return (
    <Form
      inputs={{
        email: {
          label: "Email",
          type: "email",
          required: true,
        },
      }}
      buttonText="Send Invite"
      url="/api/invite"
      method="POST"
    />
  );
}

function OrganisationName() {
  return (
    <Fragment>
      <Card>
        <Form
          inputs={{
            name: {
              label: "Company Name",
              type: "text",
              required: true,
            },
          }}
          buttonText={false}
          url="/api/account/name"
          method="PATCH"
          submitOnChange
        />
      </Card>
    </Fragment>
  );
}

function AddLocations() {
  const practices = useAPI("/api/practice");
  const [practiceList, setPracticeList] = useState([]);

  useEffect(() => {
    if (practices?.data?.length > 0) {
      setPracticeList(practices.data);
    }
  }, [practices.data]);

  const handleDelete = (row) => {
    const newList = practiceList.filter((item) => item.id !== row.id);
    Axios.delete(`/api/practice/${row.id}`);
    setPracticeList(newList);
  };

  return (
    <Fragment>
      <Card>
        <Form
          inputs={{
            name: {
              label: "Location Name",
              type: "text",
              required: true,
            },
            active: {
              type: "hidden",
              value: true,
            },
            nickname: {
              label: "Nickname",
              type: "text",
              required: false,
            },
          }}
          buttonText="Add"
          url="/api/practice"
          method="POST"
          callback={(res) => {
            console.log("data", res.data);
            setPracticeList([...practiceList, res.data.data]);
          }}
        />
      </Card>
      <Card>
        <Table
          data={practiceList}
          show={["name", "nickname"]}
          // actions={{ delete: handleDelete }}
        />
      </Card>
    </Fragment>
  );
}
