import React, { Fragment, useState, useCallback } from "react";
import { Form, useAPI, Card, AccountNav } from "components/lib";
import { useNavigate } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import "survey-core/defaultV2.css";
import Axios from "axios";
import { useEffect } from "react";
StylesManager.applyTheme("defaultV2");

export function Exclusions(props) {
  const accountInfo = useAPI("/api/account");

  // form json
  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    pages: [
      {
        name: "info",
        elements: [
          {
            type: "checkbox",
            name: "service",
            title: "Which services need to be excluded?",
            colCount: 2,
            maxSelectedChoices: 20,
            choices: [
              {
                value: "Assessments",
                text: "Psychological Assessments",
              },
              {
                value: "Psychology",
                text: "Psychological Therapy/Counselling",
              },
              { value: "Hypnotherapy", text: "Hypnotherapy" },
              { value: "Occupational Therapy", text: "Occupational Therapy" },
              {
                value: "Occupational Therapy Assessment",
                text: "Occupational Therapy Assessment",
              },
              { value: "Speech Therapy", text: "Speech Therapy" },
              { value: "Group Therapy", text: "Group Therapy" },
              { value: "Chiropractic", text: "Chiropractic" },
              { value: "Osteopathy", text: "Osteopathy" },
              { value: "Physiotherapy", text: "Physiotherapy" },
              { value: "Support Coordination", text: "Support Coordination" },
              { value: "Recovery Coaching", text: "Recovery Coaching" },
              {
                value: "School Leaver Employment Services (SLES)",
                text: "School Leaver Employment Services (SLES)",
              },
              { value: "Dietetics", text: "Dietetics" },
              { value: "Nutrition", text: "Nutrition" },
              { value: "Exercise Physiology", text: "Exercise Physiology" },
              { value: "Psychiatry", text: "Psychiatry" },
              { value: "Group Therapy", text: "Group Therapy" },
              {
                value: "Home Medication Review",
                text: "Home Medication Review",
              },
              { value: "Diabetes Educator", text: "Diabetes Educator" },
              { value: "Lactation Consultant", text: "Lactation Consultant" },
              {
                value: "Functional Medicine Practitioner",
                text: "Functional Medicine Practitioner",
              },
            ],
          },
          {
            type: "radiogroup",
            name: "groupTherapySubCategories",
            title:
              "Please select the type of group therapy you are wanting to exclude?",
            maxSelectedChoices: 9,
            choices: [
              "Handwriting Group",
              "Life Skills Group",
              "Mother's Support Course",
              "Support to Manage Behaviour, Everyday Tasks & Meltdowns",
              "Adventure Club",
              "Little Steps Playgroup & Mother's Support Group",
              "Art Therapy",
              "Social Skills",
              "Parenting Neurodivergent Children",
              "Parenting",
            ],
          },
          {
            title:
              "Please select the type of occupational therapy you are exclude?",
            type: "tagbox",
            name: "occupationalTherapySubCategories",
            maxSelectedChoices: 17,
            choices: [
              "Sensory Processing",
              "Attention/Concentration/Self-regulation",
              "Toileting",
              "Self-care skills (e.g. hygiene, dressing)",
              "Fussy Eating",
              "Handwriting",
              "Learning challenges",
              "Acute Fractures",
              "Paediatric Injuries",
              "Surgical Tendons",
              "Post-op tendon repair",
              "Geographical barriers (People outside Townsville/remote areas)",
              "Nerve concerns",
              "Dislocation",
              "Ligament injuries",
              "Soft tissue injuries",
              "Repetitive conditions",
              "Arthritis",
            ],
          },
          {
            title:
              "Please select the type of occupational therapy assessment you are seeking?",
            type: "tagbox",
            name: "occupationalTherapyAssessmentSubCategories",
            maxSelectedChoices: 3,
            choices: [
              "Developmental assessment",
              "Functional Capacity assessment",
              "Motor Skills assessment",
              "Learning assessment",
            ],
          },
          {
            type: "checkbox",
            name: "mainReason",
            title: "Which main reasons need to be excluded?",
            choices: [
              "Mental Health",
              "Neurodivergence",
              "General Health",
              "Trauma/Harm",
              "Personal Problems and Life Stressors",
              "Gender",
              "Relationships",
              "Educational",
              "Suicidal Thinking",
              "Self Harming Behaviours",
              "Behavioural Problems",
              "Addictions",
              "Work/Community",
              "Couples/Family Therapy",
              "Legal",
            ],
            colCount: 2,
            maxSelectedChoices: 14,
          },
          {
            type: "tagbox",
            choices: [
              {
                value: "Couples Therapy",
                text: "Couples Therapy (only select if you'd like therapy with a partner)",
              },
              {
                value: "Family Therapy",
                text: "Family Therapy (only select if you'd like group therapy)",
              },
            ],
            name: "therapySubCategories",
            title: "Which therapy sub categories need to be excluded?",
            maxSelectedChoices: 1,
          },
          {
            type: "tagbox",
            name: "assessments",
            title: "Which assessments need to be excluded?",
            maxSelectedChoices: 13,
            choices: [
              "ASD Assessment",
              "ADHD Assessment",
              "Autism Assessment",
              "Carer and Vocational Assessment",
              "Compensation Assessment (workplace accident, medical negligence,victim of crime, motor vehicle)",
              "Educational Assessment",
              "Functional Assessment",
              "Immigration Assessment",
              "Impairment Assessment",
              "Intellectual/Cognitive Assessment",
              "Disability Support Pension (DSP) Assesment",
              "Neuropsychological Assessment",
              "Personality Assessment",
              "Psychopathology Assessment",
              "Bariatric Pre-Surgery Assessment",
            ],
          },
          {
            type: "tagbox",
            name: "neurodivergenceSubCategories",
            title: "Which neurodivergence sub categories need to be excluded?",
            choices: [
              "Attention Deficit Hyperactivity Disorder",
              "Autism Spectrum Disorder",
              "Autism",
            ],
            otherText: "Other (please specify)",
            maxSelectedChoices: 2,
          },
          {
            type: "tagbox",
            name: "mentalHealthSubCategories",
            title: "What mental health sub categories need to be excluded?",
            choices: [
              "Adjustment Disorder",
              "Anxiety & Phobias",
              "BiPolar Disorder",
              "Body Dysmorphia",
              "Depression",
              "Dissociative Identity Disorder",
              "Eating Disorders",
              "Hoarding Disorder",
              "Obsessive Compulsive Disorder (OCD)",
              "Panic Disorder",
              "Personality Disorders",
              {
                label: "Peri/post Natal depression/anxiety",
                value: "Post-natal Depression/Anxiety",
              },
              "Psychosis",
              "Schizophrenia",
              "Selective Mutism",
              "Situational Mutism",
              "Tourette’s Syndrome (Tic Disorder)",
              "Trichotillomania (hair pulling)",
              "Excoriation (Skin picking)",
            ],
            maxSelectedChoices: 18,
          },
          {
            type: "tagbox",
            name: "generalHealthSubCategories",
            title: "What General Health issues need to be excluded?",
            choices: [
              "Chronic Disease Management",
              "Genetic Counselling",
              "Health-related problems",
              "Anxiety about my health and physical symptoms",
              "Infertility Issues",
              "Memory Problems",
              "Pain Management",
              "Physical Disability",
              "Pre-cosmetic Surgery Assessment",
              "Perimenopause, menopause, postmenopause",
              "Rehab/Injury Counselling",
              "Sleeping problems",
              "Stress Management",
              "Weight Management",
              "Toileting problems (Encopresis; Enuresis)",
              "Chronic Illness",
              "General Health Issues",
            ],
            maxSelectedChoices: 16,
          },
          {
            type: "tagbox",
            name: "traumaSubCategories",
            title: "Which Trauma/Harm issues need to be excluded?",
            choices: [
              "Adult Survivor of Sexual Abuse",
              "Birth trauma",
              "Complex Developmental Trauma",
              "Complex PTSD",
              "Bullying",
              "Child Abuse",
              "Disaster Recovery",
              "Family Violence",
              "Medical trauma",
              "Post Traumatic Stress Disorder (PTSD)",
              "Self-harm",
              "Sexual Abuse",
              "Suicide",
              "Victim of Crime",
            ],
            maxSelectedChoices: 12,
          },
          {
            type: "tagbox",
            name: "personalSubCategories",
            title:
              "Please select the types of Personal Problems and Life Stressors issues need to be excluded?",
            choices: [
              "Anger Management",
              "Assertiveness Training",
              "Behaviour Problems",
              "Body Image",
              "Carer Support",
              "Grief and Loss",
              "Infertility Issues",
              "Life Transition and Adjustment Issues",
              "Emotion dysregulation",
              "Sport Performance",
              "Pregnancy Support",
              "Parenting Support",
              "Religious Issues",
              "Self Esteem & Self Development",
              "Sexual Difficulties",
              "Social Skills",
              "Stress management",
            ],
            maxSelectedChoices: 15,
          },
          {
            type: "tagbox",
            name: "genderSubCategories",
            title:
              "Please select the types of Gender issues need to be excluded?",
            choices: [
              "Diverse Genders",
              "Diverse Sexualities",
              "Gender Dysphoria",
            ],
            maxSelectedChoices: 2,
          },
          {
            type: "tagbox",
            name: "relationshipSubCategories",
            title:
              "Please select the types of Relationship issues need to be excluded?",
            choices: [
              "Conflict Resolution",
              "Couples Therapy",
              "Divorce/Separation",
              "Family Therapy",
              "Mediation",
              "Parenting",
              "Relationships",
              "Attachment disruption (fostering and adoption)",
            ],
            maxSelectedChoices: 6,
          },
          {
            type: "tagbox",
            name: "educationalSubCategories",
            title:
              "Please select the types of Educational issues need to be excluded?",
            choices: [
              "Academic Performance",
              "Intellectual Disability",
              "Learning Difficulties",
              "School Refusal",
              "School Attendance Issues",
              "Problems with Peers",
              "Specific Learning Disorder",
              "Developmental Delays",
            ],
            maxSelectedChoices: 6,
          },
          {
            type: "tagbox",
            name: "addictionSubCategories",
            title:
              "Please select the types of Addiction issues need to be excluded?",
            choices: [
              "Alcohol dependence",
              "Drug dependence",
              "Gambling",
              "Impulsive Behaviours",
              "Internet Gaming / Social Networking",
              "Pornography / Sex Addiction",
              "Smoking",
            ],
            maxSelectedChoices: 6,
          },
          {
            type: "tagbox",
            name: "workSubCategories",
            title:
              "Please select the type of Work/Community issues need to be excluded?",
            choices: [
              "Employee Assistance Program",
              "Human Resource Management",
              "Leadership",
              "Organisational Restructuring",
              "Military, Police, & Emergency Work",
              "Performance Management",
              "Personnel Selection",
              "Work Stress",
              "Workplace Bullying",
              "Executive Coaching",
              "Workplace Accident",
            ],
            maxSelectedChoices: 10,
          },
          {
            type: "tagbox",
            name: "legalSubCategories",
            title:
              "Please select the types of Legal issues need to be excluded?",
            choices: [
              "Criminal Behaviour",
              "Expert Witness",
              "Family Court Issues",
              "Legal Report",
              "Management of Offenders",
              "Sex Offenders",
            ],
            maxSelectedChoices: 5,
          },
          {
            type: "tagbox",
            name: "subPersonalityDisorders",
            title:
              "Please select the types of Personality Disorder issues need to be excluded?",
            choices: [
              "Borderline Personality Disorder ",
              "Narcissistic Personality Disorder",
              "Antisocial Personality Disorder",
              "Paranoid Personality Disorder",
              "Dependent Personality Disorder ",
              "Obsessive Compulsive Personality Disorder",
            ],
            maxSelectedChoices: 5,
          },
          {
            type: "tagbox",
            name: "eatingSubCategories",
            title:
              "Please select the types of Eating Disorder issues need to be excluded?",
            choices: [
              "Anorexia Nervosa",
              "Bulimia Nervosa ",
              "Binge eating",
              "Pica (eating non-food substances)",
              "Avoidant/Restrictive Food Intake",
            ],
            maxSelectedChoices: 4,
          },
          {
            type: "tagbox",
            name: "anxietySubCategories",
            title:
              "Please select the types of Anxiety and Phobia issues need to be excluded?",
            choices: [
              "General Anxiety",
              "Social Anxiety",
              "Specific Phobias",
              "Agoraphobia (difficulty being outside in public spaces)",
              "Attachment anxiety (children)",
            ],
            maxSelectedChoices: 4,
          },
          {
            type: "checkbox",
            name: "whichSpeciality",
            title: "Which speciality needs to be excluded?",
            maxSelectedChoices: 15,
            choices: [
              "Cognitive Behaviour Therapy (CBT)",
              "Schema Therapy",
              "Acceptance and Commitment Therapy (ACT)",
              "Interpersonal Psychotherapy (IPT)",
              "Eye Movement Desensitisation and Reprocessing Therapy (EMDR)",
              "ABA Therapy",
              "Cognitive Processing Therapy",
              "Solution Focused Therapy",
              "Coaching",
              "Couples Therapy",
              "Family Therapy",
              "Psychodynamic Therapy ",
              "Dialectical Behaviour Therapy",
              "Play Therapy",
              "Cognitive-Behavioural Intergrated Therapy (C-BIT)",
              "Neuroaffirming",
              "Other",
            ],
          },
          {
            type: "tagbox",
            name: "whoPays",
            title: "Which referral types need to be excluded?",
            maxSelectedChoices: 13,
            choices: [
              "Self funded",
              "Self (with Medicare Rebate)",
              "Self (with private insurance rebate)",
              "Department of Veterans Affairs (DVA)",
              "National Disability Insurance Scheme (NDIS)",
              "WorkSafe",
              "Workcover",
              "Transport Accident Commission (TAC)",
              "Open Arms",
              "Victims of Crime",
              "Women’s Resource Information and Support Centre (WRISC)",
              "Employer Funded (Employee Assistance Program)",
              "Chronic Disease Management Plan",
              "Enhanced Primary Care Plan",
              "Team Care Arrangement",
            ],
          },
          {
            type: "boolean",
            name: "hideTelehealthPreference",
            title: "Hide Telehealth Preference",
          },
        ],
      },
    ],
    showQuestionNumbers: "off",
  };

  function setExistingExclusions() {
    if (!accountInfo?.data?.settings) {
      return;
    }
    const categories = [
      "mainReason",
      "therapySubCategories",
      "assessments",
      "neurodivergenceSubCategories",
      "mentalHealthSubCategories",
      "generalHealthSubCategories",
      "traumaSubCategories",
      "personalSubCategories",
      "genderSubCategories",
      "relationshipSubCategories",
      "educationalSubCategories",
      "addictionSubCategories",
      "workSubCategories",
      "legalSubCategories",
      "subPersonalityDisorders",
      "eatingSubCategories",
      "anxietySubCategories",
      "occupationalTherapySubCategories",
      "occupationalTherapyAssessmentSubCategories",
      "whichSpeciality",
      "whoPays",
      "service",
    ];

    model.setValue(
      "hideTelehealthPreference",
      accountInfo?.data?.exclusions?.hideTelehealthPreference || false
    );

    // to do setup existing exclusions
    categories.forEach((category) => {
      if (accountInfo?.data?.exclusions?.[category]) {
        model.setValue(category, accountInfo?.data?.exclusions?.[category]);
      }
    });
  }

  const createOrUpdateAccountExclusions = useCallback((sender) => {
    saveSurveyResults("/api/account/settings", sender.data);
  });

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.patch(url, {
      exclusions: json,
    });
  }

  // create model
  const model = new Model(json);
  model.onComplete.add(createOrUpdateAccountExclusions);

  if (accountInfo.loading || accountInfo.data === undefined) {
    return <div>Loading...</div>;
  }

  setExistingExclusions();
  return (
    <Fragment>
      <AccountNav />
      <Card
        title="Exclusions"
        loading={accountInfo?.loading && accountInfo?.data}
      >
        <p>
          Here’s your chance to customise your client form. Please be aware that
          the presenting issues are linked to a matching algorithm and allow you
          to gather information about prospective clients. Please be sure that
          you don’t want this information before you exclude something. You
          aren’t able to add anything due to the complexities of matching,
          however, please email{" "}
          <a className="text-blue-500" href="mailto:admin@katina.com.au">
            admin@katina.com.au
          </a>{" "}
          if there’s something missing for you. Please note, if you exclude a
          Main Reason then you exclude all Sub Categories under that main
          reason. If you’d like to remove individual sub categories, please do
          so from the sub categories sections. You can come back to this at
          anytime and update it. However, these updates won’t apply to any
          clients who have already filled the form out so it’s best to get this
          as correct as possible from the start
        </p>
        {<Survey model={model} />}
      </Card>
    </Fragment>
  );
}
