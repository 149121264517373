import { Dashboard } from "views/dashboard/dashboard";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { ClientView } from "views/client/client";
import { Match } from "views/client/match";
import { InstructionsView } from "views/dashboard/instructions";
import { BulkEmails } from "views/client/bulk";
import { ClientUpdate } from "views/client/update";

const Routes = [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "Dashboard",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/client/:id",
    view: ClientView,
    layout: "app",
    permission: "user",
    title: "Client",
  },
  {
    path: "/client/:id/update",
    view: ClientUpdate,
    layout: "app",
    permission: "admin",
    title: "Client Update",
  },
  {
    path: "/instructions",
    view: InstructionsView,
    layout: "app",
    permission: "user",
    title: "Instructions",
  },
  {
    path: "/match/:id",
    view: Match,
    layout: "app",
    permission: "user",
    title: "Match View",
  },
  {
    path: "/bulk",
    view: BulkEmails,
    layout: "app",
    permission: "admin",
    title: "Bulk Emails",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
];

export default Routes;
