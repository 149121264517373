import { Card, useAPI } from "components/lib";
import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import { StylesManager, Model, FunctionFactory } from "survey-core";
import { SurveyQuestionQuill } from "./QuillComponent";

import "survey-core/defaultV2.css";
import Axios from "axios";

StylesManager.applyTheme("defaultV2");

export function TemplateSettings(props) {
  const { id } = useParams();
  const templateInfo = useAPI(`/api/template/${id}`);

  const json = {
    completedHtml: "<h3>Your changes have been saved.</h3>",
    completeText: "Save",
    navigateToUrl: "/account/client/templates",
    questions: [
      {
        type: "text",
        name: "name",
        title: "Name",
        isRequired: true,
      },
      {
        type: "text",
        name: "subject",
        title: "Subject",
        isRequired: true,
      },
      {
        type: "text",
        name: "reply_to",
        title: "Reply To",
        description: "One way email capability. Please add a reply to email. "
        // isRequired: true,
      },
      {
        type: "quill",
        name: "body",
        title: "Body",
        description: "To add a prospective clients name into the template please enter the following dynamic term {{nаme}}",
        isRequired: true,
      },
    ],
  };

  function setExistingSettings() {
    if (!templateInfo?.data) {
      return;
    }

    model.setValue("name", templateInfo.data?.[0]?.name);
    model.setValue("subject", templateInfo.data?.[0]?.subject);
    model.setValue("reply_to", templateInfo.data?.[0]?.reply_to);
    model.setValue("body", templateInfo.data?.[0]?.body);

  }

  const editTemplates = useCallback((sender) => {
    saveSurveyResults("/api/template/" + id, sender.data);
  });

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    const response = await Axios.patch(url, {
      name: json.name,
      subject: json.subject,
      reply_to: json.reply_to,
      body: json.body,
    });
  }

  const model = new Model(json);
  model.onComplete.add(editTemplates);
  setExistingSettings();

  return (
    <div>
      <Card
        // title="Edit Template"
        loading={templateInfo?.loading && templateInfo?.data}
      >
        <p className="text-sm text-gray-600 italic">To add a prospective clients name into the template please enter the following dynamic term {"{{nаme}}"}</p>
        {<Survey model={model} />}
      </Card>
    </div>
  );
};

