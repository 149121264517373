import React, { Fragment, useCallback, useContext } from "react";
import { useAPI, Card, ViewContext, AccountNav } from "components/lib";
import Axios from "axios";
import { Survey } from "survey-react-ui";
import psychFormJson from "./psych_form_json";
import "survey-core/defaultV2.css";
import { StylesManager, Model } from "survey-core";
import { useNavigate } from "react-router-dom";

StylesManager.applyTheme("defaultV2");

export function ManagePsychProfile(props) {
  const psychProfile = useAPI("/api/user/psych_profile");
  const locations = useAPI("/api/practice");
  const navigate = useNavigate();
  const context = useContext(ViewContext);
  console.log(
    "psychFormJson.pages[1].elements[23].",
    psychFormJson.pages[1].elements[26]
  );
  psychFormJson.pages[1].elements[26].templateElements[0].choices =
    locations?.data?.map((l) => {
      return { value: l.id, text: l.name };
    });

  const surveyComplete = useCallback((sender) => {
    console.log("sender.data", sender);
    saveSurveyResults("/api/user/psych_profile", sender.data);
  }, []);

  const survey = new Model(psychFormJson);
  survey.focusFirstQuestionAutomatic = true;
  survey.data = psychProfile?.data;

  survey.onComplete.add(surveyComplete);

  function saveSurveyResults(url, json) {
    console.log("json", json);
    Axios.post(url, json);
    navigate("/dashboard");
  }

  return (
    <Fragment>
      <AccountNav />
      <Card>
        {!psychProfile?.loading && !locations?.loading && (
          <Survey model={survey} />
        )}
      </Card>
    </Fragment>
  );
}
